import { ICellRendererParams, RowNode } from "ag-grid-enterprise";
import {
  toReadableNumber,
  toReadablePercentage,
  getFormattedValue,
} from "../../helpers/numbers";
import { aggregateColumn } from "./aggregators";
import { isNil } from "lodash";
import * as React from "react";
import { useContext } from "react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  MinusIcon,
} from "@heroicons/react/20/solid";

export interface ICombinedCellRendererParamsColumn {
  name?: string;
  type: string;
  aggFunc: string;
  result?: number | null;
}

interface ICombinedCellRendererParams {
  columns: ICombinedCellRendererParamsColumn[];
}

interface ILinkRendererParams {
  domain: string;
}

export function PercentageValueCellRenderer(props: ICellRendererParams) {
  let isThresholdReached = false;
  let positive = false;
  let zero = false;

  // TODO: rather than handle special cases in the function def, parameterize
  // this behavior
  if (props.value[0] > 0) {
    positive = true;
  } else if (
    props.value[0] === 0 ||
    props.value[0] === null ||
    isNaN(props.value[0])
  ) {
    zero = true;
    // } else if (props.value === 0) {
    //   isThresholdReached = false;
  }

  // let classes = isThresholdReached ? "threshold-reached" : "";
  // classes += " " + props?.colDef?.field;
  // if (positive) {
  //   classes += " positive";
  // }

  // Set icon
  let icon = <ChevronDownIcon fill="red" stroke="red" height="25" />;
  if (positive) {
    icon = <ChevronUpIcon fill="green" stroke="green" height="25" />;
  } else if (zero) {
    icon = <MinusIcon fill="black" stroke="black" height="25" />;
  }

  // console.log(props.value);
  // console.log(toReadablePercentage(props.value, true));
  // console.log(typeof props.value);

  // if (typeof props.value === "number") {
  return (
    //className={classes}
    <span className="inline-flex">
      {toReadablePercentage(props.value, true)} {icon}
    </span>
  );
  // }
  // return null;
}

// export function ReadableNumberCellRenderer(props: ICellRendererParams) {
//   let isThresholdReached = false;
//   let positive = false;

//   let localClasses = isThresholdReached ? "threshold-reached" : "";
//   localClasses += " " + props?.colDef?.field;
//   if (positive) {
//     localClasses += " positive";
//   }

//   // Set icon
//   // let icon = <RedArrowIcon />;
//   if (positive) {
//     // icon = <GreenArrowIcon />;
//   }

//   const staticValues = [
//     "rank_change",
//     "rank",
//     "rank_cy",
//     "rank_of_comp_intel_week_total_sales",
//   ];

//   if (typeof props.value === "number") {
//     let value: any = toReadableNumber(props.value, true);

//     if (props?.colDef?.field && staticValues.includes(props?.colDef?.field)) {
//       value = props.value;
//     }

//     return (
//       <>
//         <span className={localClasses}>
//           <span>{value}</span>
//         </span>
//         {props?.colDef?.field === "rank_change" && (
//           <div className={classes.rankCellIcon}>{value ? icon : null}</div>
//         )}
//       </>
//     );
//   }

//   if (typeof props.data === "number") {
//     let value: any = toReadableNumber(props.data, true);

//     if (props?.colDef?.field && staticValues.includes(props?.colDef?.field)) {
//       value = props.value;
//     }

//     return (
//       <span className={isThresholdReached ? "threshold-reached" : ""}>
//         <span>
//           {value} {isThresholdReached ? icon : null}
//         </span>
//       </span>
//     );
//   }
//   return null;
// }

// export function CurrencyReadableNumberCellRenderer(props: ICellRendererParams & ILinkRendererParams) {
//   const userCtx = useContext(UserContext);
//   let currentCountry = userCtx.user.currentCountry;
//   const currency = currencies[currentCountry].currency;

//   if (!props.value) {
//     return `${getCurrencySymbol(currency)}0.00`;
//   }

//   return `${getCurrencySymbol(currency)}${toReadableNumber(props.value)}`;
// }

// export function PercentageValueCellRenderer(props: ICellRendererParams) {
//   let isThresholdReached = false;
//   let positive = false;

//   if (props.colDef?.field === "second_period_weighted_lbb_perc") {
//     if (props?.value * 100 > 10) {
//       isThresholdReached = true;
//     }
//   }

//   // TODO: rather than handle special cases in the function def, parameterize
//   // this behavior
//   if (
//     props?.colDef?.field === "share_change" ||
//     props?.colDef?.field === "total_share_pt_change" ||
//     props?.colDef?.field === "change_1p" ||
//     props?.colDef?.field === "change_3p" ||
//     props?.colDef?.field === "dynamic_comparison_sum_total_sales_perc_change" ||
//     props?.colDef?.field === "change_total_asp" ||
//     props?.colDef?.field === "change_total_units" ||
//     props?.colDef?.field === "perc_change"
//   ) {
//     isThresholdReached = true;

//     if (props.value > 0) {
//       positive = true;
//     } else if (props.value === 0) {
//       isThresholdReached = false;
//     }
//   }

//   let classes = isThresholdReached ? "threshold-reached" : "";
//   classes += " " + props?.colDef?.field;
//   if (positive) {
//     classes += " positive";
//   }

//   // Set icon
//   let icon = <RedArrowIcon />;
//   if (positive) {
//     icon = <GreenArrowIcon />;
//   }

//   if (typeof props.value === "number") {
//     return (
//       <span className={classes}>
//         <span>
//           {toReadablePercentage(props.value, true)}{" "}
//           {isThresholdReached ? icon : null}
//         </span>
//       </span>
//     );
//   }
//   return null;
// }

export function ImageRenderer(props: ICellRendererParams) {
  return (
    <a href={props.value} target={"_blank"}>
      <img src={props.value} />
    </a>
  );
}

export function UrlRenderer(props: ICellRendererParams) {
  return (
    <a href={props.value} target={"_blank"}>
      {props.value}
    </a>
  );
}

// export function CombinedCellRenderer(
//   props: ICellRendererParams & ICombinedCellRendererParams
// ) {
//   if (props.value === undefined && props.data === undefined) {
//     return null;
//   }
//   // if rendering cell representing aggregation, use props.value
//   let params: ICombinedCellRendererParamsColumn[] = props.value || [];

//   // if rendering individual cell, use props.data
//   // NOTE: in  this case, props.data will be a single row, not an array. Still, we want to
//   // leverage the calculations definted in aggregateColumn
//   if (!params.length) {
//     params = props.columns.map((column: ICombinedCellRendererParamsColumn) => {
//       const result = aggregateColumn([props as unknown as RowNode], column);
//       return { ...column, result };
//     });
//   }

//   const columnValuesFormatted: string[] = params
//     .filter((param) => !isNil(param.result) && !isNaN(param.result))
//     .map((param) => {
//       // show 2 decimal places for ASP, 1 decimal place for all other table values
//       const options =
//         param.aggFunc === "ASP" ? { round: false } : { round: true };
//       // multiply buybox value with 100 for correct format
//       if (param.name?.includes("buybox_winning_price")) {
//         return getFormattedValue(Number(param.result) * 100, param.type);
//       }
//       return getFormattedValue(Number(param.result), param.type, options);
//     });

//   const firstValue = columnValuesFormatted.length
//     ? Number(columnValuesFormatted[0].replace(/[^0-9.]/g, ""))
//     : "";
//   let isThresholdReached = false;

//   // Check for array, second value percent but only show if both are available
//   if (
//     params.length >= 2 &&
//     params[1].type === "percent" &&
//     columnValuesFormatted.length > 0
//   ) {
//     let isPositive = (params[1].result || 0) > 0;

//     if ((params[1].name || "").includes("chargebacks")) {
//       isPositive = (params[1].result || 0) < 0.05;
//     }

//     // Set icon
//     let icon = <RedArrowIcon />;
//     if (isPositive) {
//       icon = <GreenArrowIcon />;
//     }

//     // Determine thresholds
//     if (props?.colDef?.field === "chargebacks") {
//       if (firstValue > 1) {
//         isThresholdReached = true;
//       }
//     }
//     if (
//       props?.colDef?.field === "roas_branded" ||
//       props?.colDef?.field === "roas_competitive" ||
//       props?.colDef?.field === "roas_generic"
//     ) {
//       if (firstValue < 0) {
//         isThresholdReached = true;
//       }
//     }

//     return (
//       <>
//         <div className={classes.gridCell}>
//           <span className={isThresholdReached ? "threshold-reached" : ""}>
//             <span>{columnValuesFormatted[0]}</span>
//           </span>
//           <span> {columnValuesFormatted[1] ? "|" : null}</span>
//           <span>{columnValuesFormatted[1]}</span>
//         </div>
//         <div className={classes.gridCellIcon}>
//           {columnValuesFormatted[1] ? icon : null}
//         </div>
//       </>
//     );
//   }

//   if (props?.colDef?.field === "RepOOSPercent" && firstValue > 3) {
//     isThresholdReached = true;
//   }

//   if (props?.colDef?.field === "netppm" && firstValue > 10) {
//     isThresholdReached = true;
//   }

//   return (
//     <span className={isThresholdReached ? "threshold-reached" : ""}>
//       <span>{columnValuesFormatted[0]}</span>
//     </span>
//   );
// }
