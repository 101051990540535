import React, { useEffect, useState, useContext, Fragment } from "react";
import { FilterContext } from "../../../store/Filter-context";
import { getQueryConfig } from "../../../config/bigquery/query-configs";
import GetBigqueryData from "../../../config/hooks/bigquery";
// import { filter, initial } from "lodash";
import { IFilterSelectionsSales } from "../GlobalFilters";
import { AutocompleteDropdown } from "../elements/Combobox";
import dayjs from "dayjs";
import { formatDate } from "../../../helpers/Dates_ngk";
import { UserContext } from "../../../store/User-context";

interface IProps {
  selectedFiltersSummary: (filters: IFilterSelectionsSales) => void;
  setShowFilterDrawer: (showFilter: boolean) => void;
  setDrawerStatus: (orderStatus: boolean) => void;
}

export const initialFiltersSales = () => {
  return {
    customerCategory: [],
    customer: [],
    itemClass: [],
    orderStatus: [],
    sku: [],
    // salesOrderID: [],
    dateRanges: initialDateRanges(),
  };
};

const initialDateRanges = () => {
  let startDate = formatDate(dayjs().startOf("year"));
  let endDate = formatDate(dayjs());

  let newSelectedDateRanges = {
    startDate: startDate,
    endDate: endDate,
  };
  return newSelectedDateRanges;
};

const SalesFilters = (props: IProps) => {
  const filterCtx = useContext(FilterContext);
  const userCtx = useContext(UserContext);
  const ERP = userCtx.user.profile.currentClient.ERP.name;

  const bigqueryQueryConfigs = getQueryConfig("SalesFilters", ERP) || "";

  const { queryData: dataRaw, isLoading: isLoadingDataRaw } =
    GetBigqueryData(bigqueryQueryConfigs);

  const [selectedFilters, setSelectedFilters] = useState<any>(
    filterCtx.filtersSales || initialFiltersSales()
  );

  const [isReset, setIsReset] = useState<boolean>(false);

  const updateGlobalFilters = (salesFilters: IFilterSelectionsSales) => {
    const newFilters = {
      ...salesFilters,
      dateRanges: filterCtx.filtersSales.dateRanges,
    };
    filterCtx.setFiltersSales(newFilters);
    props.selectedFiltersSummary(newFilters);
    setSelectedFilters(newFilters);
  };

  useEffect(() => {
    setSelectedFilters(initialFiltersSales());
  }, [userCtx]);

  useEffect(() => {
    if (filterCtx.filtersSales) {
      setSelectedFilters(filterCtx.filtersSales);
    }
  }, [filterCtx.filtersSales]);

  const handleSelectChange = (value: any, filter: any) => {
    const newFilterState: IFilterSelectionsSales = {
      ...selectedFilters,
      [filter]: value,
    };

    setSelectedFilters(newFilterState);
    setIsReset(false);
  };

  const resetFilters = () => {
    setSelectedFilters(initialFiltersSales());
    setIsReset(true);
  };

  const handleApply = () => {
    updateGlobalFilters(selectedFilters);
    props.setShowFilterDrawer(false);
    props.setDrawerStatus(false);
  };

  const generateObjectForData = (itemValue: any) => {
    const label =
      itemValue !== null && itemValue !== undefined
        ? itemValue.replaceAll("_", " ")
        : null;
    return {
      label: label,
      value: itemValue,
    };
  };

  let customerCategoryData;
  let customerData;
  let itemClassData;
  let orderStatusData;
  let skuData: string[] = [];
  let salesOrderIdData: string[] = [];

  if (dataRaw) {
    customerCategoryData = [
      ...new Set([...dataRaw].map((i: any) => i.customerCategory)),
    ].sort();
    customerData = [
      ...new Set([...dataRaw].map((i: any) => i.customer)),
    ].sort();
    itemClassData = [
      ...new Set([...dataRaw].map((i: any) => i.itemClass)),
    ].sort();
    orderStatusData = [
      ...new Set([...dataRaw].map((i: any) => i.orderStatus)),
    ].sort();
    skuData = [...new Set([...dataRaw].map((i: any) => i.sku))].sort();
    salesOrderIdData = [
      ...new Set([...dataRaw].map((i: any) => i.salesOrderID)),
    ].sort();
  }

  const dropdownConfig = [
    {
      label: "Customer Category",
      name: "customerCategory",
      options: customerCategoryData?.map((i: any) => generateObjectForData(i)),
      type: "dropdown",
    },
    {
      label: "Customer",
      name: "customer",
      options: customerData?.map((i: any) => generateObjectForData(i)),
      type: "dropdown",
    },
    {
      label: "Item Class",
      name: "itemClass",
      options: itemClassData?.map((i: any) => generateObjectForData(i)),
      type: "dropdown",
    },
    {
      label: "Status",
      name: "orderStatus",
      options: orderStatusData?.map((i: any) => generateObjectForData(i)),
      type: "dropdown",
    },
    {
      label: "SKU",
      name: "sku",
      options: skuData?.map((i: any) => generateObjectForData(i)),
      type: "sku",
      defaultOption: "All",
      placeholder: "Loading SKU options...",
    },
    // {
    //   label: "Sales Order ID",
    //   name: "salesOrderID",
    //   options: salesOrderIdData?.map((i: any) => generateObjectForData(i)),
    //   type: "salesOrderID",
    //   defaultOption: "All",
    //   placeholder: "Loading Sales Order IDs...",
    // },
  ];

  return (
    <>
      <div className="relative mt-6 flex-1 px-4 sm:px-6">
        <div>
          {dropdownConfig.map((config: any) => {
            return (
              <div key={config.name} className="pt-2 pb-3">
                {/* {config.type === 'dropdown' ? ( */}
                <AutocompleteDropdown
                  filter={config}
                  sendChanges={handleSelectChange}
                  options={config.options}
                  reset={isReset}
                />
                {/* // ) : null} */}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={resetFilters}
        >
          Reset
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={handleApply}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default SalesFilters;
