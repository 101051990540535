import {
    ICombinedCellRendererParamsColumn,
    ImageRenderer,
    UrlRenderer,
    PercentageValueCellRenderer,
} from "./cellRenderers";
import { ColDef, ITooltipParams, RowNode } from "ag-grid-community";
import {
    aggregateColumn,
    GrossSalesValueGetter,
    GrossSalesFunc,
    ratioFormatter,
} from "./aggregators";
import {
    ColGroupDef,
    ValueGetterParams,
} from "ag-grid-community/dist/lib/entities/colDef";
import { ICellRendererParams } from "ag-grid-enterprise";
import {
    toReadableDollar,
    toReadableNumber,
    toReadablePercentage,
} from "../../helpers/numbers";

const COMBINED_VALUE_WIDTH = 175;
const SINGLE_VALUE_WIDTH = 100;

function columnComparatorBasic(
    valueA: any,
    valueB: any,
    _rowNodeA: any,
    _rowNodeB: any,
    isInverted: boolean
) {
    if (valueA === valueB) {
        return 0;
    }
    if (typeof valueA === "number" && typeof valueB === "number") {
        return valueA - valueB;
    }
    if (valueA === null) {
        return isInverted ? -1 : 1;
    }
    if (valueB === null) {
        return isInverted ? 1 : -1;
    }
    return valueA[0]?.result - valueB[0]?.result;
}

function columnComparator(
    sortCol: ICombinedCellRendererParamsColumn[],
    isCalculatedColumn: boolean
) {
    return function (
        valueA: { [key: string]: any },
        valueB: { [key: string]: any },
        rowNodeA: RowNode,
        rowNodeB: RowNode
    ) {
        if (valueA === undefined && valueB === undefined) {
            let a: number | null = null;
            let b: number | null = null;
            if (isCalculatedColumn) {
                a = aggregateColumn([rowNodeA], sortCol[0]);
                b = aggregateColumn([rowNodeB], sortCol[0]);
            } else {
                a = rowNodeA?.data[sortCol[0].name || ""];
                b = rowNodeB?.data[sortCol[0].name || ""];
            }
            return a !== null && b !== null ? a - b : 0;
        }
        return valueA[0]?.result - valueB[0]?.result;
    };
}

function createValueObject(thisYear: number, lastYear: number) {
    return {
        thisYear: thisYear,
        lastYear: lastYear,
        toString: () => `${thisYear && lastYear ? thisYear / lastYear - 1 : 0}`,
    };
}

// export function retailSales(currency: string): ColDef {
//   const columns = [
//     { name: "second_period_ordered_revenue", type: currency, aggFunc: "sum" },
//     { type: "percent", aggFunc: "OrderedRevEvoPOP" },
//   ];
//   return {
//     field: "retail_sales",
//     resizable: true,
//     sortable: true,
//     comparator: columnComparator(columns, false),
//     headerName: "Retail Sales",
//     cellRenderer: CombinedCellRenderer,
//     width: COMBINED_VALUE_WIDTH,
//     cellRendererParams: {
//       columns,
//     },
//     aggFunc: "combinedValsAggregator",
//   };
// }

export function TimePeriod(pinned?: boolean): ColDef {
    return {
        field: "timePeriod",
        resizable: true,
        sortable: true,
        headerName: "Time Period",
        width: SINGLE_VALUE_WIDTH,
        tooltipField: "timePeriod",
        pinned: pinned ? "left" : null,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
    };
}

export function Customer(pinned?: boolean): ColDef {
    return {
        field: "customer",
        resizable: true,
        sortable: true,
        headerName: "Customer",
        width: SINGLE_VALUE_WIDTH * 1.5,
        tooltipField: "customer",
        pinned: pinned ? "left" : null,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
    };
}

export function CustomerCategory(pinned?: boolean): ColDef {
    return {
        field: "customerCategory",
        resizable: true,
        sortable: true,
        headerName: "Customer Category",
        width: SINGLE_VALUE_WIDTH,
        tooltipField: "customerCategory",
        pinned: pinned ? "left" : null,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
    };
}

export function ItemClass(pinned?: boolean): ColDef {
    return {
        field: "itemClass",
        resizable: true,
        sortable: true,
        headerName: "Item Class",
        width: SINGLE_VALUE_WIDTH * 1.5,
        tooltipField: "itemClass",
        pinned: pinned ? "left" : null,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
    };
}

export function sku(pinned?: boolean): ColDef {
    return {
        field: "sku",
        resizable: true,
        sortable: true,
        headerName: "SKU",
        width: SINGLE_VALUE_WIDTH,
        tooltipField: "sku",
        pinned: pinned ? "left" : null,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
    };
}

export function OrderStatus(pinned?: boolean): ColDef {
    return {
        field: "orderStatus",
        resizable: true,
        sortable: true,
        headerName: "Order Status",
        width: SINGLE_VALUE_WIDTH,
        tooltipField: "orderStatus",
        pinned: pinned ? "left" : null,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
    };
}

export function GrossSales(): ColDef {
    return {
        field: "grossSales",
        resizable: true,
        sortable: true,
        headerName: "Gross Sales",
        width: SINGLE_VALUE_WIDTH,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossSalesLY(): ColDef {
    return {
        field: "grossSalesLY",
        resizable: true,
        sortable: true,
        headerName: "Gross Sales LY",
        width: SINGLE_VALUE_WIDTH,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossSalesChng(): ColDef {
    return {
        colId: "GrossSalesChng",
        resizable: true,
        sortable: true,
        headerName: "Gross Sales ▲",
        width: SINGLE_VALUE_WIDTH,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueGetter: (params: ValueGetterParams) => {
            if (!(params.node && params.node.group)) {
                return params.data!.grossSales - params.data!.grossSalesLY;
            }
        },
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossSalesYOY(): ColDef {
    const columns = [{ type: "percent", aggFunc: "GrossSalesYOY" }];
    return {
        colId: "grossSalesYOY",
        resizable: true,
        sortable: true,
        headerName: "Gross Sales YOY",
        width: SINGLE_VALUE_WIDTH,
        cellRendererParams: {
            columns,
        },
        cellRenderer: PercentageValueCellRenderer,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "singleValsAggregator",
        valueGetter: (params: ValueGetterParams) => {
            if (!(params.node && params.node.group)) {
                // no need to handle group levels - calculated in the 'aggFunc'
                return createValueObject(
                    params.data!.grossSales,
                    params.data!.grossSalesLY
                );
            }
        },
    };
}

export function GrossQuantity(): ColDef {
    return {
        field: "grossQuantity",
        resizable: true,
        sortable: true,
        headerName: "Gross Quantity",
        width: SINGLE_VALUE_WIDTH,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossQuantityLY(): ColDef {
    return {
        field: "grossQuantityLY",
        resizable: true,
        sortable: true,
        headerName: "Gross Quantity LY",
        width: SINGLE_VALUE_WIDTH,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossQuantityYOY(): ColDef {
    const columns = [{ type: "percent", aggFunc: "GrossQuantityYOY" }];
    return {
        colId: "GrossQuantityYOY",
        resizable: true,
        sortable: true,
        headerName: "Gross Quantity YOY",
        width: SINGLE_VALUE_WIDTH,
        cellRenderer: PercentageValueCellRenderer,
        cellRendererParams: {
            columns,
        },
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "singleValsAggregator",
        valueGetter: (params: ValueGetterParams) => {
            if (!(params.node && params.node.group)) {
                // no need to handle group levels - calculated in the 'aggFunc'
                return createValueObject(
                    params.data!.grossQuantity,
                    params.data!.grossQuantityLY
                );
            }
        },
    };
}

export function GrossTax(): ColDef {
    return {
        field: "grossTax",
        resizable: true,
        sortable: true,
        headerName: "Gross Tax",
        width: SINGLE_VALUE_WIDTH,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossTaxLY(): ColDef {
    return {
        field: "grossTaxLY",
        resizable: true,
        sortable: true,
        headerName: "Gross Tax LY",
        width: SINGLE_VALUE_WIDTH,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossAdditionalCharges(): ColDef {
    return {
        field: "grossAdditionalCharges",
        resizable: true,
        sortable: true,
        headerName: "Gross Add. Charges",
        width: SINGLE_VALUE_WIDTH * 1,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossAdditionalChargesLY(): ColDef {
    return {
        field: "grossAdditionalChargesLY",
        resizable: true,
        sortable: true,
        headerName: "Gross Add. Charges LY",
        width: SINGLE_VALUE_WIDTH * 1,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function NetSales(): ColDef {
    return {
        field: "netSales",
        resizable: true,
        sortable: true,
        headerName: "Net Sales",
        width: SINGLE_VALUE_WIDTH * 1,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function NetSalesLY(): ColDef {
    return {
        field: "netSalesLY",
        resizable: true,
        sortable: true,
        headerName: "Net Sales LY",
        width: SINGLE_VALUE_WIDTH * 1,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function NetSalesYOY(): ColDef {
    const columns = [{ type: "percent", aggFunc: "NetSalesYOY" }];
    return {
        colId: "NetSalesYOY",
        resizable: true,
        sortable: true,
        headerName: "Net Sales YOY",
        width: SINGLE_VALUE_WIDTH,
        cellRenderer: PercentageValueCellRenderer,
        cellRendererParams: {
            columns,
        },
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "singleValsAggregator",
        valueGetter: (params: ValueGetterParams) => {
            if (!(params.node && params.node.group)) {
                // no need to handle group levels - calculated in the 'aggFunc'
                return createValueObject(
                    params.data!.netSales,
                    params.data!.netSalesLY
                );
            }
        },
    };
}

export function GrossProductMargin(): ColDef {
    return {
        field: "grossProfit",
        resizable: true,
        sortable: true,
        headerName: "Gross Product Margin",
        width: SINGLE_VALUE_WIDTH * 1,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossProductMarginLY(): ColDef {
    return {
        field: "grossProfitLY",
        resizable: true,
        sortable: true,
        headerName: "Gross Product Margin LY",
        width: SINGLE_VALUE_WIDTH * 1,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function GrossProductMarginYOY(): ColDef {
    const columns = [{ type: "percent", aggFunc: "GrossProductMarginYOY" }];
    return {
        colId: "GrossProductMarginYOY",
        resizable: true,
        sortable: true,
        headerName: "Gross Product Margin YOY",
        width: SINGLE_VALUE_WIDTH,
        cellRenderer: PercentageValueCellRenderer,
        cellRendererParams: {
            columns,
        },
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "singleValsAggregator",
        valueGetter: (params: ValueGetterParams) => {
            if (!(params.node && params.node.group)) {
                // no need to handle group levels - calculated in the 'aggFunc'
                return createValueObject(
                    params.data!.grossProfit,
                    params.data!.grossProfitLY
                );
            }
        },
    };
}

export function COGSamount(): ColDef {
    return {
        field: "cogsAmount",
        resizable: true,
        sortable: true,
        headerName: "Product COGS",
        width: SINGLE_VALUE_WIDTH * 1,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function COGSamountLY(): ColDef {
    return {
        field: "cogsAmountLY",
        resizable: true,
        sortable: true,
        headerName: "Product COGS LY",
        width: SINGLE_VALUE_WIDTH * 1,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function AvgSales(): ColDef {
    const columns = [{ type: "number", aggFunc: "AvgSales" }];
    return {
        field: "avgSales",
        resizable: true,
        sortable: true,
        // comparator: columnComparator(columns, true),
        headerName: "Avg Sales",
        width: SINGLE_VALUE_WIDTH * 1,
        cellRendererParams: {
            columns,
        },
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "singleValsAggregator",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function AvgQuantity(): ColDef {
    const columns = [{ type: "number", aggFunc: "AvgQuantity" }];
    return {
        field: "avgQuantity",
        resizable: true,
        sortable: true,
        headerName: "Avg Quantity",
        width: SINGLE_VALUE_WIDTH * 1,
        cellRendererParams: {
            columns,
        },
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "singleValsAggregator",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}

export function NumberOfOrders(): ColDef {
    return {
        field: "numberOfOrders",
        resizable: true,
        sortable: true,
        headerName: "Number of Orders",
        width: SINGLE_VALUE_WIDTH * 1,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: "agNumberColumnFilter",
        aggFunc: "sum",
        valueFormatter: (params) =>
            params.value !== null
                ? toReadableNumber(params.value, true, true)
                : params.value,
    };
}
