import React from "react";
import { IFilterSelectionsSales } from "../components/forms/GlobalFilters";

export interface FilterState {
  filtersSales: IFilterSelectionsSales;
  // filterHierarchy: ISelectOption[];
  setFiltersSales?: (filters: IFilterSelectionsSales) => void;
  // setFilterHierarchy?: (filterHierarchy: ISelectOption[]) => void;
  resetFilters?: () => void;
  // autocompleteOptions: string[];
  // setAutocompleteOptions?: (autocompleteOptions: object[]) => void;
  // isDateFilterDirty: boolean;
  // setIsDateFilterDirty?: (isDirty: boolean) => void;
}

export const FilterContext = React.createContext({
  filtersSales: {} as IFilterSelectionsSales,
  // filterHierarchy: [] as ISelectOption[],
  setFiltersSales: (filterSelections: IFilterSelectionsSales) => {},
  // setFilterHierarchy: (filterHierarchy: ISelectOption[]) => {},
  resetFilters: () => {},
  // autocompleteOptions: [],
  // setAutocompleteOptions: (autocompleteOptions: object[]) => {},
  // isDateFilterDirty: false,
  // setIsDateFilterDirty: (isDirty: boolean) => {},
});
