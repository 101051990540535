import { useEffect, useState } from "react";
import useAxios from "../../utils/useAxios";
import { AxiosError } from "axios";
import { Base64 } from "js-base64";

interface IQueryDataItemError {
  bigquery_error: string;
}

interface IQueryDataItemAny {
  [key: string]: any;
}

type IQueryDataItem = IQueryDataItemError | IQueryDataItemAny;

function GetBigqueryData(query: string) {
  const [res, setRes] = useState("");
  const api = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [queryData, setQueryData] = useState<IQueryDataItem[]>([]);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const env = process.env.REACT_APP_ENV || "dev";
        // if local development env, then send queries as string, else obfuscate with base64
        let data = {};
        if (env !== "development") {
          const b64query = Base64.encode(query);
          data = {
            query: b64query,
          };
        } else {
          data = {
            query: query,
          };
        }
        const response = await api.postForm("/bigquery/data/", data);
        if (!abortController.signal.aborted) {
          setRes(response.data.response);
          setIsLoading(false);
          setQueryData(response.data.response);
        } else {
          setQueryData([]);
          setIsLoading(false);
        }
      } catch (error: AxiosError | any) {
        setServerError(error);
        setIsLoading(false);
      }
    };
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return { queryData, isLoading, serverError };
}

export default GetBigqueryData;
