import React, { useEffect, useState, useContext } from "react";
import { FilterContext } from "../../../store/Filter-context";
import { initialFiltersSales } from "../globalFilters/SalesFilters";
import Select from "react-select";

interface Props {
  sendChanges: (value: any | null, filter: any) => void;
  reset: boolean;
  options: string[];
  defaultOption?: string[];
  placeholder?: string;
  filter: any;
}

export const AutocompleteDropdown = (props: Props) => {
  const { sendChanges, reset, defaultOption, filter } = props;

  const filterCtx = useContext(FilterContext);
  const [selectedFilters, setSelectedFilters] = useState<any>(
    filterCtx.filtersSales || initialFiltersSales()
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    const filterContents = (selectedFilters as any)[filter.name] || [];
    setSelectedOptions(filterContents);
  }, [selectedFilters, filter.name]);

  useEffect(() => {
    if (reset) {
      setSelectedOptions([]);
    }
  }, [reset]);

  useEffect(() => {
    if (selectedOptions) {
      sendChanges(selectedOptions, filter.name);
    }
  }, [selectedOptions, filter.name]);

  const areOptionsLoading = !Boolean(props.options.length);

  return (
    <div className="pt-2">
      <h1>{filter.label}</h1>
      <Select
        isMulti
        isDisabled={areOptionsLoading}
        value={selectedOptions || []}
        id={props.filter.name}
        onChange={(selections: any) => {
          setSelectedOptions(selections);
        }}
        options={props.options}
        closeMenuOnSelect={false}
      />
    </div>
  );
};
