import { useContext } from "react";
import { UserContext } from "../../store/User-context";
import StuffedLogo from "../../assets/images/stuffedpuffslogo.svg";
import TiaLuptiaLogo from "../../assets/images/tialupitalogo.webp";
import Logo from "../../assets/images/thedashforward-fav.svg";
import OttosNaturalsLogo from "../../assets/images/ottosnaturals.avif";
import WaterBuffaloLogo from "../../assets/images/WaterBuffalo.svg";

export interface IClientImageMap {
  [imageName: string]: string;
}

export function getClientImage(imageName: string) {
  const clientImageConfigTemplates: IClientImageMap = {
    "Stuffed Puffs": StuffedLogo,
    "Tia Lupita": TiaLuptiaLogo,
    "Ottos Naturals": OttosNaturalsLogo,
    "Water Buffalo": WaterBuffaloLogo,
  };

  if (!Object.keys(clientImageConfigTemplates).includes(imageName)) {
    // throw new Error(`Requested invalid client image: ${imageName}`);
    return Logo;
  }
  const clientImageConfig = clientImageConfigTemplates[imageName];
  return clientImageConfig;
}

export const ClientImage = () => {
  const userCtx = useContext(UserContext);
  let local_currentClient;
  if (window.localStorage.getItem("dashforward_currentClient")) {
    local_currentClient = window.localStorage.getItem(
      "dashforward_currentClient"
    );
  }
  const currentClient = userCtx.user.profile?.currentClient?.name;
  const clientImage =
    local_currentClient !== undefined && local_currentClient !== null
      ? getClientImage(local_currentClient)
      : getClientImage(currentClient);

  return (
    <div
      className={`${
        currentClient === "Ottos Naturals" ||
        local_currentClient === "Ottos Naturals"
          ? "rounded-lg bg-white p-1"
          : ""
      }`}
    >
      <img className="max-h-12" src={clientImage} alt="" />
    </div>
  );
};
