import classes from "../../styles/CustomChart.module.scss";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getFormattedValue } from "../../helpers/numbers";
import {
  Chart as ChartJS,
  ChartType,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  BarController,
  Title,
  Tooltip,
  Legend,
  Filler,
  DoughnutController,
} from "chart.js";
import SpinnerProps from "./Spinner";
import { netsuiteChartAttributes } from "../../config/charts/netsuiteChartConfigs";
import { dearChartAttributes } from "../../config/charts/dearChartConfigs";
import PieChartLegends from "./PieChartLegends";
// import { FilterContext, FilterState } from '../../store/Filter-context';
import { useContext, useEffect } from "react";
// import { ISelectOption } from '../../config/filters';
import GetBigqueryData from "../../config/hooks/bigquery";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  BarController,
  Title,
  Tooltip,
  Legend,
  Filler,
  DoughnutController
);

interface IProps {
  chartTitle: string;
  queryConfig: string;
  type?: ChartType;
  chartBoxLabel: string;
  currency: string;
  ERP: string;
  timePeriod?: string;
  filterOverrides?: { [filterName: string]: string };
  rowLimit?: number;
  source?: string;
}

interface IDataRow {
  [key: string]: any;
}

const getChartData = (
  dataRows: IDataRow[],
  chartTitle: string,
  chartBoxLabel: string,
  currency: string,
  ERP: string
  // filterHierarchy: ISelectOption[],
  // globalFilter: FilterState
) => {
  if (ERP == "Netsuite") {
    let { labels, dataSetConfigs, chartOptions } = netsuiteChartAttributes({
      chartTitle,
      dataRows,
      chartBoxLabel,
      currency,
      // filterHierarchy,
      // globalFilter,
    });
    return {
      data: {
        labels,
        datasets: dataSetConfigs,
      },
      options: chartOptions,
    };
  } else if (ERP == "DEAR") {
    let { labels, dataSetConfigs, chartOptions } = dearChartAttributes({
      chartTitle,
      dataRows,
      chartBoxLabel,
      currency,
      // filterHierarchy,
      // globalFilter,
    });
    return {
      data: {
        labels,
        datasets: dataSetConfigs,
      },
      options: chartOptions,
    };
  }
  throw new Error(`Unsupported ERP: ${ERP}. Valid ERPs are Netsuite & DEAR.`);
};

const VIZ_WITH_DATE_RANGE_COMPARISON = [
  "Dynamic Stacked Pie Chart",
  "Sales Driver Chart",
];

function CustomChart(props: IProps) {
  // const filterCtx = useContext(FilterContext);

  //TODO: update conditional selection of hook ticket amzwa-294
  // const sourceDrivenDataQueryHook = props?.source === 'Comp Intel' ? useLookerCompIntelQueryData : useLookerQueryData;

  const { queryData: data, isLoading } = GetBigqueryData(props.queryConfig);

  const { data: chartData, options } = getChartData(
    data,
    props?.chartTitle,
    props.chartBoxLabel,
    props.currency,
    props.ERP
    // filterCtx.filterHierarchy,
    // filterCtx
  );
  let containerClassName = "";
  if (props?.chartTitle === "Dynamic Stacked Pie Chart") {
    containerClassName = "donutContainer";
  } else if (props.chartBoxLabel === "ci-price-band") {
    containerClassName = classes.horizontalBar;
  }

  return (
    <div
      className={`${
        isLoading ? "h-500 mt-5 flex items-center justify-center" : ""
      } `}
    >
      {isLoading ? (
        <SpinnerProps
          height="250"
          width="auto"
          classNames="flex-auto justify-center"
        ></SpinnerProps>
      ) : (
        <div className={`${containerClassName}`}>
          {options.plugins && options.plugins.doughnutlabel && (
            <div className="dounutTextCenter">
              <span className="firstTextCenter">
                {getFormattedValue(
                  options.plugins.doughnutlabel.labels[0].text,
                  "number",
                  {
                    expanded: true,
                    round: true,
                  }
                )}
              </span>
              <span className="secondTextCenter">
                vs{" "}
                {getFormattedValue(
                  options.plugins.doughnutlabel.labels[1].text,
                  "number",
                  {
                    expanded: true,
                    round: true,
                  }
                )}
              </span>
            </div>
          )}
          <Chart
            className={classes.chart}
            type={props.type || "bar"}
            data={chartData}
            options={options}
            plugins={[]}
            // plugins={props.type === 'bar' ? [ChartDataLabels] : []}
          />
          {props?.chartTitle === "Dynamic Stacked Pie Chart" && (
            <PieChartLegends data={chartData} />
          )}
        </div>
      )}
    </div>
  );
}

export default CustomChart;
