import { IDataRow } from "../config/scorecards/scorecards";

// Remove view prefix from object keys (content before .)
export const cleanChartData = (dataRows: IDataRow[]) => {
  return dataRows.map((item: IDataRow) => {
    const returnItem: IDataRow = {};
    for (const [key, value] of Object.entries(item as IDataRow)) {
      if (!key.includes(".")) {
        returnItem[key] = value;
      } else {
        let newKey: any = key.split(".")[1];
        returnItem[newKey] = value;

        if (value && typeof value === "object") {
          for (const [subKey, subValue] of Object.entries(value as IDataRow)) {
            if (!subKey.includes(".")) {
              returnItem[newKey][subKey] = subValue;
            } else {
              let newSubKey: any = subKey.split(".")[1];
              returnItem[newKey][newSubKey] = subValue;
            }
          }
        }
      }
    }
    return returnItem;
  });
};

const chartLabelEnum = [
  {
    originalLabel: "Gross Sales over Time",
    newLabel: "Gross Sales over Time",
  },
  {
    originalLabel: "Product Sales",
    newLabel: "Product Sales by Class",
  },
  {
    originalLabel: "Product Sales",
    newLabel: "Product Sales by Class",
  },
  {
    originalLabel: "Customer Sales",
    newLabel: "Customer Sales by Customer Category",
  },
];

// Get new label from enum or return original if not found
export const getModifiedChartLabel = (chart: string) => {
  const found = chartLabelEnum.find((x) => x.originalLabel === chart);
  return found ? found.newLabel : chart;
};
