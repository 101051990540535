import { UserState, User, UserContext } from "./User-context";
import { useReducer } from "react";

interface Props {
  children: React.ReactNode;
}

enum UserActionKind {
  SET = "SET",
  RESET = "RESET",
  SET_CLIENT = "SET_CLIENT",
}

interface UserAction {
  type: UserActionKind;
  payload?: User;
  client?: string;
}

const defaultUserState = {
  user: {} as User,
};

function userReducer(state: UserState, action: UserAction) {
  if (action.type === "SET") {
    const updatedUser = action.payload ? action.payload : state.user;
    return { user: updatedUser };
  }
  if (action.type === "SET_CLIENT") {
    if (action.client) {
      const updatedUser = state.user;
      updatedUser["profile"]["currentClient"]["name"] = action.client;
    }
    const updatedUser = state.user;
    return { user: updatedUser };
  }

  if (action.type === "RESET") {
    return defaultUserState;
  }
  return defaultUserState;
}

export const UserProvider = (props: Props) => {
  const [UserState, dispatchUserAction] = useReducer(
    userReducer,
    defaultUserState
  );

  const setUserState = (user: User) => {
    dispatchUserAction({ type: UserActionKind.SET, payload: user });
  };

  const setClientState = (client: string) => {
    dispatchUserAction({ type: UserActionKind.SET_CLIENT, client: client });
  };

  const resetUserState = () => {
    dispatchUserAction({ type: UserActionKind.RESET });
  };

  const userContext = {
    user: UserState.user,
    setUser: setUserState,
    setClient: setClientState,
    resetUser: resetUserState,
  };
  return (
    <UserContext.Provider value={userContext}>
      {props.children}
    </UserContext.Provider>
  );
};
