import { map, get, isNil, last, divide } from "lodash"; //sumBy, findLastIndex
import { getFormattedValue } from "../../helpers/numbers";
import {
  IDataRow,
  IScoreCardConfig,
  IScoreCardCalloutConfig,
} from "./scorecards";

const getStatus = (value?: number): IScoreCardCalloutConfig["status"] => {
  if (!value) {
    return "neutral" as IScoreCardCalloutConfig["status"];
  }
  let status = "positive";
  if (value < 0) {
    status = "negative";
  }
  return status as IScoreCardCalloutConfig["status"];
};

const PERIOD = "timePeriod";

const getChartData = (
  data: IDataRow[],
  xAxisCol: string,
  yAxisCol: string,
  yAxisColLY?: string
): IScoreCardConfig["chartData"] => {
  const xAxis = map(data, xAxisCol);
  const yAxis = map(data, yAxisCol);
  const yAxisLY = map(data, yAxisColLY);
  if (yAxisColLY) {
    return {
      xAxis,
      yAxis,
      yAxisLY,
    };
  } else {
    return {
      xAxis,
      yAxis,
    };
  }
};

export function getNetsuiteScoreCardConfig(
  scoreCardName: string,
  currency: string,
  data: IDataRow[]
) {
  const lastRow = last(data);
  const scoreCardConfigs: { [name: string]: () => IScoreCardConfig } = {
    "Gross Sales": () => {
      const callouts: IScoreCardCalloutConfig[] = [];
      const grossSalesTotal = get(lastRow, "grossSalesTotal");
      const grossSalesTotalLY = get(lastRow, "grossSalesTotalLY");
      const grossSalesYOY = grossSalesTotal / grossSalesTotalLY - 1;
      if (!isNil(grossSalesTotalLY)) {
        callouts.push({
          status: "neutral",
          id: "grossSalesTotalLY",
          value: `${getFormattedValue(grossSalesTotalLY, currency, {
            round: true,
            expanded: true,
          })} LY`,
        });
      }
      if (!isNil(grossSalesTotalLY)) {
        callouts.push({
          status: "neutral",
          id: "grossSalesYOY",
          value: `${getFormattedValue(grossSalesYOY, "percent", {
            round: true,
          })} vs LY`,
        });
      }
      return {
        chartData: getChartData(data, PERIOD, "grossSales", "grossSalesLY"),
        callouts,
        KPIValue: getFormattedValue(grossSalesTotal, currency, {
          round: true,
          expanded: true,
        }),
        KPIType: currency,
      };
    },
    "Gross Quantity": () => {
      const callouts: IScoreCardCalloutConfig[] = [];
      const grossQuantityTotal = get(lastRow, "grossQuantityTotal");
      const grossQuantityTotalLY = get(lastRow, "grossQuantityTotalLY");
      const grossQuantityYOY = grossQuantityTotal / grossQuantityTotalLY - 1;
      if (!isNil(grossQuantityTotalLY)) {
        callouts.push({
          status: "neutral",
          id: "grossQuantityTotalLY",
          value: `${getFormattedValue(grossQuantityTotalLY, "number", {
            round: true,
          })} LY`,
        });
      }
      if (!isNil(grossQuantityTotalLY)) {
        callouts.push({
          status: "neutral",
          id: "grossQuantityYOY",
          value: `${getFormattedValue(grossQuantityYOY, "percent", {
            round: true,
          })} vs LY`,
        });
      }
      return {
        chartData: getChartData(
          data,
          PERIOD,
          "grossQuantity",
          "grossQuantityLY"
        ),
        callouts,
        KPIValue: getFormattedValue(grossQuantityTotal, "number", {
          round: true,
        }),
        KPIType: currency,
      };
    },
    Orders: () => {
      const callouts: IScoreCardCalloutConfig[] = [];
      const avgQuantityTotal = get(lastRow, "avgQuantityTotal");
      if (!isNil(avgQuantityTotal)) {
        callouts.push({
          status: "neutral",
          id: "avgQuantityTotal",
          value: `${getFormattedValue(avgQuantityTotal, "number", {
            round: true,
          })} Avg. Order Quantity`,
        });
      }
      const avgSalesTotal = get(lastRow, "avgSalesTotal");
      if (!isNil(avgSalesTotal)) {
        callouts.push({
          status: "neutral",
          id: "avgSalesTotal",
          value: `${getFormattedValue(avgSalesTotal, currency, {
            round: true,
          })} Avg. Order Sales`,
        });
      }
      const avgPriceTotal = avgSalesTotal / avgQuantityTotal;
      if (!isNil(avgPriceTotal)) {
        callouts.push({
          status: "neutral",
          id: "avgPriceTotal",
          value: `${getFormattedValue(avgPriceTotal, currency, {
            round: true,
          })} Avg. Order Price`,
        });
      }
      const numberOfOrdersTotal = get(lastRow, "numberOfOrdersTotal");
      return {
        chartData: getChartData(
          data,
          PERIOD,
          "numberOfOrders",
          "numberOfOrdersLY"
        ),
        callouts,
        KPIValue: getFormattedValue(numberOfOrdersTotal, "number", {
          round: false,
          expanded: true,
        }),
        KPIType: currency,
      };
    },
    "Product COGS": () => {
      const callouts: IScoreCardCalloutConfig[] = [];
      const cogsAmountTotal = get(lastRow, "cogsAmountTotal");
      const cogsAmountTotalLY = get(lastRow, "cogsAmountTotalLY");
      const cogsAmountTotalYOY = cogsAmountTotal / cogsAmountTotalLY - 1;
      if (!isNil(cogsAmountTotalLY)) {
        callouts.push({
          status: "neutral",
          id: "cogsAmountTotalLY",
          value: `${getFormattedValue(cogsAmountTotalLY, currency, {
            round: true,
            expanded: true,
          })} LY`,
        });
      }
      if (!isNil(cogsAmountTotalLY)) {
        callouts.push({
          status: "neutral",
          id: "cogsAmountTotalYOY",
          value: `${getFormattedValue(cogsAmountTotalYOY, "percent", {
            round: true,
          })} vs LY`,
        });
      }
      return {
        chartData: getChartData(data, PERIOD, "cogsAmount", "cogsAmountLY"),
        callouts,
        KPIValue: getFormattedValue(cogsAmountTotal, currency, {
          round: true,
          expanded: true,
        }),
        KPIType: currency,
      };
    },
    "Gross Product Margin": () => {
      const callouts: IScoreCardCalloutConfig[] = [];
      const grossSalesTotal = get(lastRow, "grossSalesTotal");
      const grossProfitTotal = get(lastRow, "grossProfitTotal");
      const grossSalesTotalLY = get(lastRow, "grossSalesTotalLY");
      const grossProfitTotalLY = get(lastRow, "grossProfitTotalLY");
      const grossMargin = divide(grossProfitTotal, grossSalesTotal);
      const grossMarginLY = divide(grossProfitTotalLY, grossSalesTotalLY);
      if (!isNil(grossSalesTotal)) {
        callouts.push({
          status: "neutral",
          id: "grossMargin",
          value: `${getFormattedValue(grossMargin, "percent", {
            round: true,
          })} Gross Margin %`,
        });
      }
      if (!isNil(grossSalesTotalLY)) {
        callouts.push({
          status: "neutral",
          id: "grossMarginLY",
          value: `${getFormattedValue(grossMarginLY, "percent", {
            round: true,
          })} Gross Margin % LY`,
        });
      }
      return {
        chartData: getChartData(data, PERIOD, "grossProfit", "grossProfitLY"),
        callouts,
        KPIValue: getFormattedValue(grossProfitTotal, currency, {
          round: true,
          expanded: true,
        }),
        KPIType: currency,
      };
    },
    "Avg. Order Quantity": () => {
      const callouts: IScoreCardCalloutConfig[] = [];
      const avgQuantityTotal = get(lastRow, "avgQuantityTotal");
      return {
        chartData: getChartData(data, PERIOD, "avgQuantity"),
        callouts,
        KPIValue: getFormattedValue(avgQuantityTotal, currency, {
          round: true,
        }),
        KPIType: currency,
      };
    },
    "Avg. Order Sales": () => {
      const callouts: IScoreCardCalloutConfig[] = [];
      const avgSalesTotal = get(lastRow, "avgSalesTotal");
      return {
        chartData: getChartData(data, PERIOD, "avgSales"),
        callouts,
        KPIValue: getFormattedValue(avgSalesTotal, currency, {
          round: true,
        }),
        KPIType: currency,
      };
    },
    "Avg. Order Price": () => {
      const callouts: IScoreCardCalloutConfig[] = [];
      const avgSalesTotal = get(lastRow, "avgSalesTotal");
      const avgQuantityTotal = get(lastRow, "avgQuantityTotal");
      const avgPriceTotal = avgSalesTotal / avgQuantityTotal;
      return {
        chartData: getChartData(data, PERIOD, "avgPrice", "avgPriceLY"),
        callouts,
        KPIValue: getFormattedValue(avgPriceTotal, currency, {
          round: true,
        }),
        KPIType: currency,
      };
    },
  };
  if (!Object.keys(scoreCardConfigs).includes(scoreCardName)) {
    throw Error(
      "invalid scorecard name supplied to getScoreCardConfig: " + scoreCardName
    );
  }
  return scoreCardConfigs[scoreCardName]();
}
