export const brandColors: { [brand: string]: string } = {
  "AUTRES PUBLIC": "#E8DB2C",
  AZZARO: "#FBB883",
  BIOLAGE: "#E382A3",
  BIOTHERM: "#E382A3",
  CACHAREL: "#E081CF",
  "CAROL'S DAUGHTER": "#A17DDF",
  CERAVE: "#6299E7",
  DIESEL: "#28C7D7",
  DERMABLEND: "#2AB5E8",
  ESSIE: "#5ADABD",
  GARNIER: "#72D77C",
  HELIOTROP: "#A9B1B8",
  "IT COSMETICS": "#F2E871",
  "KERASTASE PARIS": "#FCD2AF",
  "L'OREAL MEN EXPERT": "#FFB1B5",
  "L'OREAL PARIS": "#ECA7DF",
  "L'OREAL PROFESSIONNEL": "#BFA3EB",
  "LA ROCHE-POSAY": "#8BB5F0",
  LOGONA: "#78D3F1",
  MATRIX: "#55E2F0",
  MAYBELLINE: "#78ECD3",
  "MAYBELLINE NEW YORK": "#78ECD3",
  MIXA: "#9CF4A5",
  MIZANI: "#CED3D6",
  NYX: "#FBF8CC",
  "NYX PROFESSIONAL MAKEUP": "#FBF8CC",
  PUREOLOGY: "#FDE4CF",
  REDKEN: "#FFCFD2",
  "REDKEN BREWS": "#F0C2E7",
  SANTE: "#DACAF4",
  "SOFTSHEEN-CARSON": "#B0CCF5",
  STEAMPOD: "#A3E2F6",
  THAYERS: "#A5F2F9",
  "URBAN DECAY": "#ABFAE9",
  VICHY: "#BDF7C3",
};
