// import { ReadableNumberCellRenderer } from "./cellRenderers";
import { ColDef } from "ag-grid-community";
import * as cols from "./columnDefs";

export const ASIN_LEVEL_TABLE_GROUP_COL_WIDTH = 150;
export const AGGREGATED_TABLE_COL_WIDTH = 125;

interface ISummaryTableColumnDefs {
    defaultColDef: ColDef;
    autoGroupColumnDef: ColDef;
    columnDefs: ColDef[];
}

function getSalesSummaryColumnDefs(
    currency: string,
    ERP: string
): ISummaryTableColumnDefs {
    //   const defaultCols = getDefaultColumns(currency);
    const columnDefs = (ERP: string) => {
        if (ERP === "DEAR") {
            return [
                cols.TimePeriod(),
                cols.Customer(),
                cols.CustomerCategory(),
                cols.ItemClass(),
                cols.sku(),
                cols.OrderStatus(),
                cols.GrossSales(),
                cols.GrossSalesLY(),
                cols.GrossSalesChng(),
                cols.GrossSalesYOY(),
                cols.GrossQuantity(),
                cols.GrossQuantityLY(),
                cols.GrossQuantityYOY(),
                cols.GrossTax(),
                cols.GrossTaxLY(),
                cols.GrossAdditionalCharges(),
                cols.GrossAdditionalChargesLY(),
                cols.NetSales(),
                cols.NetSalesLY(),
                cols.NetSalesYOY(),
                cols.COGSamount(),
                cols.COGSamountLY(),
                cols.GrossProductMargin(),
                cols.GrossProductMarginLY(),
                cols.GrossProductMarginYOY(),
                //   cols.AvgSales(),
                //   cols.AvgQuantity(),
                //   cols.NumberOfOrders(),
            ];
        } else {
            return [
                cols.TimePeriod(),
                cols.Customer(),
                cols.CustomerCategory(),
                cols.ItemClass(),
                cols.sku(),
                cols.OrderStatus(),
                cols.GrossQuantity(),
                cols.GrossQuantityLY(),
                cols.GrossSales(),
                cols.GrossSalesLY(),
                cols.COGSamount(),
                cols.COGSamountLY(),
                cols.GrossProductMargin(),
                cols.GrossProductMarginLY(),
                cols.GrossProductMarginYOY(),
                //   cols.AvgSales(),
                //   cols.AvgQuantity(),
                //   cols.NumberOfOrders(),
            ];
        }
    };
    return {
        defaultColDef: {
            resizable: true,
            initialWidth: 200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        autoGroupColumnDef: {
            headerName: "Group",
            field: "sku",
            resizable: true,
            width: AGGREGATED_TABLE_COL_WIDTH * 2.25,
            pinned: true,
        },
        columnDefs: columnDefs(ERP),
    };
}

export function getColumnDefs(
    view: string,
    currency: string,
    ERP: string
): ISummaryTableColumnDefs {
    const columnDefMap: {
        [view: string]: (
            currency: string,
            ERP: string
        ) => ISummaryTableColumnDefs;
    } = {
        "Sales Summary Table": getSalesSummaryColumnDefs,
    };
    if (!Object.keys(columnDefMap).includes(view)) {
        return getSalesSummaryColumnDefs(currency, ERP);
    }
    return columnDefMap[view](currency, ERP);
}
