import React from "react";

export interface User {
  username: string;
  email: string;
  first_name: string;
  id: number;
  is_active: boolean;
  is_staff: boolean;
  pk: number;
  last_name: string;
  profile: {
    is_enabled: boolean;
    currentClient: {
      name: string;
      ERP: {
        name: string;
      };
    };
    clients: [{ name: string }];
  };
}

export interface UserState {
  user: User;
  setUser?: (user: User) => void;
  resetUser?: () => void;
}

export const UserContext = React.createContext({
  user: {} as User,
  setUser: (user: User) => {},
  setClient: (client: string) => {},
  resetUser: () => {},
});
