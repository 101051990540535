import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import ScoreCard from "../components/UI/ScoreCard";
import { getSalesBigQueryConfigNamesForRole } from "../config/componentConfigs/componetConfig";
import CustomChart from "../components/UI/CustomChart";
import ChartToggleButtons from "../components/UI/ChartToggleButton";
import PeriodToggleButtons from "../components/UI/PeriodToggleButton";
import GetBigqueryData from "../config/hooks/bigquery";
import {
    getQueryConfigs,
    getQueryConfig,
} from "../config/bigquery/query-configs/index";
import { getModifiedChartLabel } from "../helpers/data";
import FilterRow from "../components/Layout/FilterRow";
import { FilterContext } from "../store/Filter-context";
import { UserContext } from "../store/User-context";
import { AgGridReact } from "ag-grid-react";
import { getColumnDefs } from "../components/tables/tableDefs";
import { sideBarWithFilterOpen } from "../components/tables/commonTableConfig";
import { aggFuncs } from "../components/tables/aggregators";
import classes from "../styles/SalesSummary.module.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { Grid, GridOptions } from "ag-grid-community";
import SalesSummaryTable from "../components/UI/SalesSummaryTable";

const ScorecardClasses =
    "col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-md";

const Customer = "Customer";
const periods = ["Day", "Week", "Month", "Quarter", "Year"];

function SalesSummary() {
    // const authCtx = useContext(AuthContext);
    // const currCountry = userCtx.user.currentCountry;
    const filterCtx = useContext(FilterContext);
    const salesFilters = filterCtx.filtersSales;

    const userCtx = useContext(UserContext);
    const ERP = userCtx.user.profile.currentClient.ERP.name;

    const {
        scorecardQueryNames,
        productChartNames,
        customerChartNames,
        // tableNames,
    } = getSalesBigQueryConfigNamesForRole("sales", ERP); //make this dynamic later when we add more pages to sales

    const [chartGroup, setChartGroup] = useState(Customer);
    const [chart, setChart] = useState<string>(customerChartNames[0]);
    const [chartPeriod, setChartPeriod] = useState<string>("Month");
    const [chartGroupItems, setChartGroupItems] =
        useState<string[]>(customerChartNames);
    // const [isFullScreen, setIsFullScreen] = useState<boolean>(false); //this is for eventual full screent table mode

    const currency = "dollar"; //make this dynamic when we want to support multi currency, half the code is in place

    function getScorecardQuery(ERP: string) {
        switch (ERP) {
            case "Netsuite":
                return "Sales Scorecards";
            case "DEAR":
                return "Sales Scorecards";
            default:
                return "Sales Scorecards";
        }
    }

    const scorecardConfig = getScorecardQuery(ERP);

    const bigqueryConfigs = getQueryConfigs(
        [scorecardConfig, ...customerChartNames],
        ERP,
        chartPeriod,
        salesFilters
    );

    // console.log(scorecardConfig);

    const {
        queryData: scorecardQueryData,
        isLoading: isScorecardQueryDataLoading,
    } = GetBigqueryData(bigqueryConfigs[scorecardConfig]);

    useEffect(() => {
        setChart(customerChartNames[0]);
        setChartGroupItems(customerChartNames);
        setChartGroup(Customer);
    }, [customerChartNames]);

    // const handleChartGroupChange = (event: any, newOption: string) => {
    //   //Used to change chart grouping later, if we want to add product divison grouping in addition to customer

    //   //prevents deselecting the same button
    //   if (newOption.length) {
    //     setChart(newOption);
    //   }
    //   if (newOption === Customer) {
    //     setChart(customerChartNames[0]);
    //   } else setChart(customerChartNames[0]);
    //   handelChartGroupItems(newOption);
    //   setChartGroup(newOption);
    // };

    const handelChartGroupItems = (item: string) => {
        if (item === Customer) {
            setChartGroupItems(customerChartNames);
        } else {
            // TODO move this into chart config
            setChartGroupItems(productChartNames);
        }
    };

    const handleChartSwitchChange = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        newChartOption: string
    ) => {
        // Prevent deselcting the same button
        if (newChartOption.length) {
            setChart(newChartOption);
        }
    };

    const handleChartPeriodSwitchChange = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        newPeriod: string
    ) => {
        // Prevents deselecting the same button
        if (newPeriod.length) {
            setChartPeriod(newPeriod);
        }
    };

    // const getChartActionIcon = (name: string) => { //for later for addinc icons to chart switching
    //   switch (name) {
    //     case 'Sales Viz - Unit Sales':
    //       return <PercentArrowUpChartIcon width="25" height="25" />;
    //     case 'Division Sales':
    //     case 'Inventory Health':
    //       return <TotalChartIcon width="25" height="25" />;
    //     case 'Traffic over Time':
    //       return <TrafficChartIcon width="25" height="25" />;
    //     case 'Traffic by Category':
    //       return <TrafficChartIcon width="25" height="25" />;
    //     case 'Retail Sales by Brand':
    //       return <BrandsChartIcon width="25" height="25" />;
    //     case 'Sales Driver Chart':
    //       return <DriverChartIcon width="25" height="25" />;
    //     case 'Retail Sales by Category':
    //       return <TextSquareChartIcon width="25" height="25" />;
    //     case '% of Retail Sales - Category':
    //       return <PercentArrowUpChartIcon width="25" height="25" />;
    //     default:
    //       return <EVOChartIcon width="25" height="25" />;
    //   }
    // };

    // const updateIsFullScreen = (status: boolean): void => {
    //   setIsFullScreen(status);
    // };

    return (
        <div>
            {/* start of page title and filter selector, should move this to a component later */}
            <header className="bg-white shadow-sm">
                <div className="mx-auto grid h-auto max-w-7xl grid-flow-row-dense grid-cols-6 grid-rows-1 py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-2xl font-semibold leading-6 text-gray-900">
                        Sales Summary
                    </h1>
                </div>
                <div className="mx-auto grid h-auto max-w-7xl grid-flow-row-dense grid-cols-6 grid-rows-1 px-2 pb-2 sm:px-6 lg:px-8">
                    <div className="col-span-6">
                        <FilterRow layer="sales"></FilterRow>
                    </div>
                </div>
            </header>
            {/* end of page title and filter selector */}
            <main>
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {/* Replace with your content */}
                        {scorecardQueryNames.map((title) => {
                            return (
                                <li key={title} className={ScorecardClasses}>
                                    <ScoreCard
                                        title={title}
                                        queryData={scorecardQueryData}
                                        isLoading={isScorecardQueryDataLoading}
                                        currency={currency}
                                        ERP={ERP}
                                    />
                                </li>
                            );
                        })}
                        {/* <div className="px-4 py-4 sm:px-0"></div> */}
                    </ul>
                    {/* /End replace */}
                </div>
                {/* END Scorecard */}
                <div className="mx-auto max-w-7xl items-center rounded-lg bg-white py-6 shadow-lg sm:px-6 lg:px-8">
                    <div className="grid grid-cols-3 justify-items-center">
                        <h2 className="inline-flex justify-self-start text-2xl font-semibold leading-6 text-gray-900">
                            {getModifiedChartLabel(chart)}
                        </h2>
                        <PeriodToggleButtons
                            title="Sales ChartButtonToggle"
                            buttons={periods}
                            onChange={handleChartPeriodSwitchChange}
                            value={chartPeriod}
                        />
                        <ChartToggleButtons
                            title="Sales ChartButtonToggle"
                            buttons={chartGroupItems}
                            onChange={handleChartSwitchChange}
                            value={chart}
                        />
                    </div>
                    <CustomChart
                        chartTitle={chart}
                        queryConfig={bigqueryConfigs[chart]}
                        chartBoxLabel="summary-executive"
                        type="bar"
                        currency={currency}
                        timePeriod={chartPeriod}
                        ERP={ERP}
                    />
                    <div className="mt-6 mb-6" />
                    {/* ${classes.aggridWrapper}` */}
                </div>
                <div className="mx-auto h-8"></div>
                {
                    /* {ERP === "DEAR" &&*/ <div className="m-5 max-w-full items-center rounded-lg bg-white py-6 shadow-lg sm:px-6 lg:px-8">
                        <div className={``}>
                            <SalesSummaryTable
                                queryName={"Sales Summary Table"}
                                ERP={ERP}
                                currency={currency}
                            />
                        </div>
                    </div>
                }
                <div className="mt-6 mb-6" />
            </main>
            <br />
        </div>
    );
}

export default SalesSummary;
