import { useReducer, ReactNode } from "react";
import { IFilterSelectionsSales } from "../components/forms/GlobalFilters";
import { FilterContext, FilterState } from "./Filter-context";
import { initialFiltersSales } from "../components/forms/globalFilters/SalesFilters";
// import { filter } from "lodash";

interface Props {
  children: ReactNode;
}

enum FilterActionKind {
  SET = "SET",
  SET_SALES = "SET_SALES",
  RESET = "RESET",
}

interface FilterAction {
  type: FilterActionKind;
  payload?: IFilterSelectionsSales;
}

const defaultFilterState = {
  filtersSales: initialFiltersSales(),
};

function filterReducer(state: FilterState, action: FilterAction) {
  if (action.type === FilterActionKind.SET_SALES) {
    const updatedFilters = action.payload ? action.payload : state.filtersSales;
    return { ...state, filtersSales: updatedFilters };
  }

  if (action.type === FilterActionKind.RESET) {
    return defaultFilterState;
  }
  return defaultFilterState;
}

export const FilterProvider = (props: Props) => {
  // @ts-ignore
  const [FilterState, dispatchFilterAction] = useReducer(
    filterReducer,
    defaultFilterState
  );

  const setFilterStateSales = (filters: IFilterSelectionsSales) => {
    // @ts-ignore
    dispatchFilterAction({
      type: FilterActionKind.SET_SALES,
      payload: filters,
    });
  };

  const resetFilterState = () => {
    // @ts-ignore
    dispatchFilterAction({ type: FilterActionKind.RESET });
  };

  const filterContext = {
    filtersSales: FilterState.filtersSales,
    setFiltersSales: setFilterStateSales,
    resetFilters: resetFilterState,
  };
  return (
    <FilterContext.Provider value={filterContext}>
      {props.children}
    </FilterContext.Provider>
  );
};
