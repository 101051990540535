import { UserContext } from "../store/User-context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  let homeMesssage =
    "Your account has been created, but is awaiting approval from an administrator.";
  const userCtx = useContext(UserContext);
  const isProfileEnabled = userCtx?.user.profile?.is_enabled;

  const navigate = useNavigate();

  if (isProfileEnabled) {
    navigate("/sales");
    return null;
  }

  return (
    <div>
      <header className="bg-white shadow-sm">
        <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            Home
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {/* Replace with your content */}
          <div className="px-4 py-4 sm:px-0">
            <div className="mx-auto max-w-7xl items-center rounded-lg bg-white py-6 shadow-lg sm:px-6 lg:px-8">
              <h4 className="px-4 py-2 font-semibold">
                {" "}
                {!isProfileEnabled
                  ? homeMesssage
                  : "Please proceed to another layer."}{" "}
              </h4>
            </div>
          </div>
          {/* /End replace */}
        </div>
      </main>
    </div>
  );
}

export default Home;
