interface ISaleSummaryQueryConfigs {
  scorecardQueryNames: string[];
  customerChartNames: string[];
  productChartNames: string[];
  tableNames: {
    aggregatedTableData: string;
    fullTableData: string;
  };
}

const salesQueryConfigsNetsuite = {
  scorecardQueryNames: [
    "Gross Sales",
    "Gross Quantity",
    "Product COGS",
    "Gross Product Margin",
    "Orders",
    // "Avg. Order Quantity",
    // "Avg. Order Sales",
    "Avg. Order Price",
  ],
  customerChartNames: ["Sales over Time", "Customer Sales", "Product Sales"],
  productChartNames: ["Sales over Time", "Customer Sales", "Product Sales"],
  tableNames: {
    aggregatedTableData: "Sales Table Expanded Aggregated",
    fullTableData: "Sales Table Expanded",
  },
};

const salesQueryConfigsDEAR = {
  scorecardQueryNames: [
    "Gross Sales",
    "Gross Quantity",
    "Gross Additional Charges",
    "Net Sales",
    "Product COGS",
    "Gross Product Margin",
    "Orders",
    // "Avg. Order Quantity",
    // "Avg. Order Sales",
    "Avg. Order Price",
  ],
  customerChartNames: ["Sales over Time", "Customer Sales", "Product Sales"],
  productChartNames: [],
  tableNames: {
    aggregatedTableData: "Sales Table Expanded Aggregated",
    fullTableData: "Sales Table Expanded",
  },
};

export function getSalesBigQueryConfigNamesForRole(
  role: string,
  ERP: string
): ISaleSummaryQueryConfigs {
  let QueryConfigs: ISaleSummaryQueryConfigs = salesQueryConfigsNetsuite;
  // const env = process.env.REACT_APP_ENV || 'dev';

  switch (role.toLowerCase() + " " + ERP.toLowerCase()) {
    case "sales netsuite":
      QueryConfigs = salesQueryConfigsNetsuite;
      break;
    case "sales dear":
      QueryConfigs = salesQueryConfigsDEAR;
      break;
    default:
      QueryConfigs = salesQueryConfigsNetsuite;
  }
  return QueryConfigs;
}
