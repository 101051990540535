interface Props {
  title: string;
  buttons: string[];
  value: string;
  onChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPeriod: string
  ) => void;
}

function ChartToggleButtons(props: Props) {
  return (
    <div
      className="float-right inline-flex rounded-md bg-gray-100 shadow-sm"
      role="group"
    >
      {props.buttons.map((name, index) => {
        let buttonMargin = "";
        if (index === 0) {
          buttonMargin = "m-2 ";
        } else buttonMargin = "mt-2 mb-2 mr-2 ";
        let activeClass = "";
        if (props.value === name) {
          activeClass = "bg-indigo-700";
        } else activeClass = "bg-indigo-500";
        return (
          <button
            type="button"
            onClick={(e) => props.onChange(e, name)}
            className={
              buttonMargin +
              activeClass +
              " rounded-md py-2.5 px-2 text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-indigo-600 focus:bg-indigo-700 focus:outline-none focus:ring-0 active:bg-indigo-800"
            }
            key={index}
          >
            {name}
          </button>
        );
      })}
    </div>
  );
}

export default ChartToggleButtons;
