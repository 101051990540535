import { useContext, useEffect, Component } from "react";
import { Header } from "./components/Layout/Header";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import useAxios from "./utils/useAxios";
import { User, UserContext } from "./store/User-context";
import AuthContext from "./config/context/AuthContext";
import { add } from "date-fns";
import TagManager from "react-gtm-module";
import { FilterContext } from "./store/Filter-context";

// async function GetUserData() {
//   const api = useAxios();
//   const loggedInUser = await api.get("/auth/user/");
//   // TODO: is this duplicative
//   const userData = await api.get(`/users/${loggedInUser.data.pk}/`);
//   return userData.data;
// }

function App() {
  const userCtx = useContext(UserContext);
  const filterCtx = useContext(FilterContext);
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx?.user?.username ? true : false;
  const routing = useRoutes(routes(isLoggedIn));
  const api = useAxios();

  const setUserHandler = (user: User) => {
    userCtx.setUser(user);
  };

  const tagManagerArgs = {
    dataLayer: {
      userId: userCtx.user?.pk,
      userName: userCtx.user?.username,
      userEmail: userCtx.user?.email,
      userEnabled: userCtx.user?.profile?.is_enabled,
      userClient: userCtx.user?.profile?.currentClient?.name,
      // filters: JSON.stringify(filterCtx.filtersSales),
    },
  };
  TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    async function userdata() {
      const GetUserData = await api.get(`/users/`);
      const userData = GetUserData.data;

      if (userData && Object.keys(userCtx.user).length === 0) {
        setUserHandler(userData);
      }

      const expiresAt = add(new Date(Date.now()), { days: 3 });
      if (userCtx.user?.profile?.currentClient?.name) {
        window.localStorage.setItem(
          "dashforward_currentClient",
          userCtx.user.profile.currentClient.name
        );
      }
    }
    userdata();
  }, [isLoggedIn, userCtx.user, setUserHandler]);

  return (
    <div>
      <Header />
      <main> {routing} </main>
    </div>
  );
}

export default App;

// gtm.oldUrl ? gtm.oldUrl : document.referrer;
// gtm.newUrl ? gtm.newUrl : window.location.href;
