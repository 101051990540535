interface Props {
  key: string | number;
  label: any;
  onClick: () => void;
}

export const Chip = (props: Props) => {
  return (
    <span
      className="text-black-800 m-1 items-center rounded-md bg-slate-200 px-2.5 py-0.5 text-sm font-medium"
      onClick={props.onClick}
    >
      {props.label}
    </span>
  );
};
