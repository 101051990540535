import { get } from "lodash";
import { getFormattedValue } from "./numbers";

interface YAxisTypes {
  [yAxisID: string]: string;
}

export const getChartTooltip = (yAxes: YAxisTypes, kpi?: string) => ({
  backgroundColor: "#00ff00",
  bodyColor: "#0000ff",
  yAlign: "top",
  enabled: false,
  external: function (context: any) {
    // Tooltip Element
    let tooltipEl: any = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = "<table></table>";
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    function getBody(dataPoint: any) {
      let rawValue = dataPoint.raw;
      if (!rawValue) {
        return "";
      }
      // the 'Sales Driver Chart' shows a range
      if (Array.isArray(rawValue)) {
        rawValue = rawValue[1] - rawValue[0];
      }
      const dataType =
        yAxes[get(context, "tooltip.dataPoints[0].dataset.yAxisID")] ||
        "number";
      if (kpi === "ASP") {
        return getFormattedValue(rawValue, dataType);
      }
      if (dataType === "percent") {
        return getFormattedValue(rawValue, dataType, { round: true });
      }
      return getFormattedValue(rawValue, dataType, {
        expanded: true,
        round: true,
      });
    }

    // Set Text
    if (tooltipModel.body) {
      let titleLines = tooltipModel.title;
      const model = tooltipModel.dataPoints[0];
      if (!titleLines.length) {
        titleLines = [
          model.dataset.label === "First Period"
            ? "Prior Period"
            : model.dataset.label,
          model.dataset.labels === "Second Period"
            ? "Current Period"
            : model.dataset.labels,
          model.label,
        ];
      }
      const bodyLines = tooltipModel.dataPoints.map(getBody);

      let label = tooltipModel.dataPoints[0]?.dataset?.label;
      let innerHtml = "";
      if (label) {
        innerHtml = "<thead>";
        innerHtml +=
          '<tr><th style="color: #fff; font-size: 12px; font-weight: 300;">' +
          label +
          "</th></tr>";
        innerHtml += "</thead><tbody>";
      }
      titleLines = titleLines.filter(Boolean);
      titleLines.forEach(function (title: string) {
        if (label) {
          innerHtml +=
            '<tr><td style="color: #fff; font-size: 12px; font-weight: 300;">' +
            title +
            "</td></tr>";
        } else {
          innerHtml = "<thead>";
          innerHtml +=
            '<tr><th style="color: #fff; font-size: 12px; font-weight: 300;">' +
            title +
            "</th></tr>";
          innerHtml += "</thead><tbody>";
        }
      });

      bodyLines.forEach(function (body: string, i: number) {
        innerHtml +=
          '<tr><td style="color: #fff; font-size: 14px; font-weight: bold;">' +
          body +
          "</td></tr>";
      });
      innerHtml += "</tbody>";

      let tableRoot = tooltipEl.querySelector("table");
      tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();
    // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.backgroundColor = "#001c30";
    tooltipEl.style.fontColor = "#fff";
    tooltipEl.style.borderRadius = "4px";
    tooltipEl.style.padding = "8px 12px";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.textAlign = "center";
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + "px";
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + 10 + "px";
    tooltipEl.style.pointerEvents = "none";
  },
});
