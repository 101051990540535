export const categoryColors: { [brand: string]: string } = {
  "BATH & SHOWER": "#E8DB2C",
  CLEANSER: "#FBB883",
  "COLOR COSMETICS": "#E382A3",
  COSMETICS: "#E081CF",
  CREAM: "#A17DDF",
  FRAGRANCE: "#6299E7",
  HAIR: "#2AB5E8",
  "HAIR CARE": "#28C7D7",
  HAIRCARE: "#28C7D7",
  "HAIR CARE APPLIANCES": "#72D77C",
  "HAIR CARE & STYLING": "#5ADABD",
  "HAIR COLOR": "#A9B1B8",
  "HAIR COLOUR PERM": "#A9B1B8",
  HYGIENE: "#F2E871",
  LOTION: "#FCD2AF",
  MAKEUP: "#FFB1B5",
  MISCELLANEOUS: "#FFB1B5",
  "NAIL CARE": "#ECA7DF",
  "ORAL CARE": "#BFA3EB",
  "PRESTIGE FRAGRANCE": "#8BB5F0",
  "PRESTIGE MAKEUP": "#78D3F1",
  "PROFESSIONAL SALON & SPA": "#55E2F0",
  "PROFESSIONAL SKIN CARE": "#78ECD3",
  SERUM: "#9CF4A5",
  SKINCARE: "#CED3D6",
  "SKIN CARE": "#CED3D6",
  "SOIN DEL LA PEAU": "#CED3D6",
  STYLING: "#2CBBCA",
  "N/A": "#8B969E",
};
