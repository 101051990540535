import { divisionColors } from "./divisionColors";
import { brandColors } from "./brandColors";
import { categoryColors } from "./categoryColors";
import { indexColors } from "./indexcolors";

export const getColor = (type: string, label: string) => {
  if (type === "division") {
    return divisionColors[label];
  }
  if (type === "brand") {
    return brandColors[label];
  }
  if (type === "category") {
    return categoryColors[label];
  }
  if (type === "index") {
    return indexColors[label];
  }
  throw new Error(`Unsupported color type: ${type}.
  Valid types are division, brand, category`);
};
