import { Line, Bar } from "react-chartjs-2";
import { first, last, slice, size } from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import {
  IScoreCardConfig,
  IScoreCardCalloutConfig,
} from "../../config/scorecards/scorecards";
import { getChartTooltip } from "../../helpers/chartTooltip";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface Props {
  data: IScoreCardConfig;
  kpi: string;
}

interface IScoreChardChartData {
  labels: string[];
  datasets: {
    label?: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    fill: boolean;
    borderWidth: number;
    pointStyle: string;
    pointRadius: number;
    pointHoverRadius: number;
    tension: number;
    pointBackgroundColor?: string;
    yAxisID: string;
  }[];
}

const getStatusForKPI = (
  config: IScoreCardConfig,
  kpi: string
): IScoreCardCalloutConfig["status"] => {
  let status = "positive";
  if (kpi === "Chargebacks") {
    let callout = config.callouts.find((c) => c.id === "% of Sales");
    status = callout ? callout.status : status;
  }
  if (kpi === "Fill Rate") {
    let callout = config.callouts.find((c) => c.id === "% to Goal");
    status = callout ? callout.status : status;
  }
  if (kpi === "Availability") {
    let callout = config.callouts.find((c) => c.id === "Rep OOS");
    status = callout ? callout.status : status;
  } else {
    let kpiValues = config.chartData.yAxis || [];
    if (
      kpiValues.length >= 2 &&
      kpiValues[0] > kpiValues[kpiValues.length - 1]
    ) {
      status = "negative";
    }
  }
  return status as IScoreCardCalloutConfig["status"];
};

// choose 10 points from list of datarows based on length of dataRows array
// use first 2 columns of query results for x and y axes
const getSparklineChartConfig = (
  config: IScoreCardConfig,
  kpi: string
): IScoreChardChartData => {
  const dates = config.chartData.xAxis;
  const data = config.chartData.yAxis;
  const dataLY = config.chartData.yAxisLY ? config.chartData.yAxisLY : [];
  const status = getStatusForKPI(config, kpi);
  const pointRadius = size(dates) < 26 ? (size(dates) > 13 ? 1 : 2) : 0;
  const pointHoverRadius = size(dates) < 13 ? 8 : 4;
  return {
    labels: dates,
    datasets: [
      {
        data: data,
        borderColor: "#6366f1",
        backgroundColor: "#F3F4F5",
        fill: false,
        borderWidth: 2,
        pointStyle: "circle",
        pointRadius: pointRadius,
        pointHoverRadius: pointHoverRadius,
        tension: 0.2,
        pointBackgroundColor: "#6366f1",
        yAxisID: "y",
      },
      {
        data: dataLY,
        borderColor: "#8B979E66",
        backgroundColor: "#F3F4F5",
        fill: false,
        borderWidth: 2,
        pointStyle: "circle",
        pointRadius: pointRadius,
        pointHoverRadius: pointHoverRadius,
        tension: 0.2,
        pointBackgroundColor: "#8B979E",
        yAxisID: "y",
      },
    ],
  };
};

export function ScoreCardSparkLineChart(props: Props) {
  const chartConfig = getSparklineChartConfig(props.data, props.kpi);
  return (
    <div>
      <Line
        data={chartConfig}
        options={{
          responsive: true,
          animation: false,
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            // @ts-ignore
            tooltip: getChartTooltip({ y: props.data.KPIType }, props.kpi),
          },
        }}
      ></Line>
    </div>
  );
}

export function ScoreCardBarChart(props: Props) {
  const chartConfig = getSparklineChartConfig(props.data, props.kpi);
  return (
    <div>
      <Bar
        data={chartConfig}
        options={{
          responsive: true,
          animation: false,
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            // @ts-ignore
            tooltip: getChartTooltip({ y: props.data.KPIType }, props.kpi),
          },
        }}
      ></Bar>
    </div>
  );
}
