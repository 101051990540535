export const divisionColors: { [division: string]: string } = {
  ACD: "#259FFF",
  CPD: "#F06F73",
  LPD: "#F3D373",
  PPD: "#8F74D0",
  Display_Ready_Case: "#E8DB2C",
  Display_Shipper: "#FBB883",
  Full_Pallet_Display: "#E382A3",
  Half_Pallet_Display: "#A17DDF",
  Quarter_Pallet_Display: "#6299E7",
  Shipping_Case: "#2AB5E8",
  C_Store: "#E8DB2C",
  Club: "#FBB883",
  Discount: "#E382A3",
  Distributor: "#A17DDF",
  Drug: "#6299E7",
  Food_Service: "#2AB5E8",
  Grocery: "#28C7D7",
  Mass: "#72D77C",
  Null: "#A9B1B8",
  Retailer: "#78D3F1",
  Wholesale: "#78ECD3",
  "Tier 1": "#78ECD3",
};
