import React, { useState, useEffect, useContext } from "react";
import { FilterContext } from "../../../store/Filter-context";
import Datepicker from "react-tailwindcss-datepicker-nd";
import { initialFiltersSales } from "../globalFilters/SalesFilters";

export interface IDateRanges {
  init: IDateRange;
  compare: IDateRange;
}

export interface IDateRange {
  // group: string;
  // selected: string;
  startDate: string;
  endDate: string;
}

// WILL USE THIS WHEN WE ADD MORE LAYERS
// interface Props {
//   // updateParent: (dateGroup: IDateRanges, zone: string) => void;
//   // selectedDateRanges: IDateRanges;
//   // options: IDateRangeOptions[];
//   label: string;
//   // id: string;
//   // zone: string;
//   filter: any;
// }

const DatePicker = () => {
  const [selectedDates, setSelectedDates] = useState<any>();

  const filterCtx = useContext(FilterContext);

  const [filterDates, setFilterDates] = useState<any>(
    filterCtx.filtersSales.dateRanges || initialFiltersSales().dateRanges
  );

  useEffect(() => {
    setSelectedDates(filterDates);
  }, [filterDates]);

  useEffect(() => {
    const updateDateRangeFilter = (dateRanges: IDateRange) => {
      filterCtx.setFiltersSales({ ...filterCtx.filtersSales, dateRanges });
    };

    if (selectedDates) {
      updateDateRangeFilter(selectedDates);
    }
  }, [selectedDates]);

  return (
    <div className="">
      <Datepicker
        inputClassName={
          "font-semibold border-gray-300 border-opacity-100 border-2 text-center"
        }
        // containerClassName="border-gray-800"
        // toggleClassName="rounded-r-lg bg-opacity-40 bg-gray-400 hover:bg-blue-800 hover:bg-opacity-60 transition-all duration-150 ease-in-out"\
        primaryColor={"indigo"}
        value={selectedDates || []}
        onChange={(selections: any) => {
          setSelectedDates(selections);
        }}
        showFooter={true}
        showShortcuts={true}
      />
    </div>
  );
};

export default DatePicker;
