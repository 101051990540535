import { filter, upperFirst, startCase } from "lodash";
import { Chip } from "../forms/elements/Chip";
import { IFilterSelectionsSales } from "../forms/GlobalFilters";

interface IProps {
  filterSummary: IFilterSelectionsSales;
  setFilterDrawerStatus: (value: boolean) => void;
  filterDrawerStatus: boolean;
}

function FilterSummary(props: IProps) {
  const handleClick = () => {
    if (props.filterDrawerStatus) {
      props.setFilterDrawerStatus(false);
    } else {
      props.setFilterDrawerStatus(true);
    }
  };

  const label = (item: string | null) => {
    let label = item;
    label = label !== null ? startCase(label.replaceAll("_", " ")) : null;
    return label;
  };

  return (
    <>
      {Object.keys(props.filterSummary).map((item: string, i: number) => {
        //@ts-ignore
        const filterItem =
          props.filterSummary[item as keyof IFilterSelectionsSales];
        if (Array.isArray(filterItem) && filterItem.length) {
          const selectedItemOptions = filterItem.map((item) =>
            typeof item === "string" ? item : item.label
          );
          const chipLabel =
            selectedItemOptions.length > 4
              ? `${selectedItemOptions[0]}, ${selectedItemOptions[1]},
                            ${selectedItemOptions[2]}, ${selectedItemOptions[3]}, ...`
              : selectedItemOptions.join(", ");
          return (
            <Chip
              key={i}
              label={
                <>
                  <strong>{label(item)}</strong>
                  <span>
                    {" "}
                    ({selectedItemOptions.length}): {chipLabel}
                  </span>
                </>
              }
              onClick={handleClick}
            />
          );
        }
      })}
    </>
  );
}

export default FilterSummary;
