import { Fragment, useState, useContext, useCallback } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from "../../assets/images/thedashforward.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../config/context/AuthContext";
import { UserContext } from "../../store/User-context";
import { ClientImage } from "../../config/clientimages";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    // 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    "https://media.licdn.com/dms/image/C5603AQGFltGqWdmNWg/profile-displayphoto-shrink_200_200/0/1658963857407?e=1676505600&v=beta&t=ru_nNGyqpn3qhP7hQuqHpOnY1PH3JJrdYiL5brkjA7M",
};
const navigation = [
  { name: "Sales Summary", href: "/sales", current: true },
  //   { name: 'Team', href: '#', current: false },
  //   { name: 'Projects', href: '#', current: false },
  //   { name: 'Calendar', href: '#', current: false },
  //   { name: 'Reports', href: '#', current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface userNavigationInterface {
  name: string;
  href: string;
  onClick: () => void;
}

export const Header = () => {
  const [menuHeader, setMenuHeader] = useState<string>("Sales Summary");
  const navigate = useNavigate();
  const checkActiveHeader = (item: string) => {
    return item == menuHeader ? true : false;
  };

  const authCtx = useContext(AuthContext);
  const isAuthenticated = authCtx?.user?.username ? true : false;
  const logoutUser = authCtx?.logoutUser;

  const userCtx = useContext(UserContext);
  const is_enabled = userCtx.user?.profile?.is_enabled;

  const handleLogout = useCallback(() => {
    logoutUser?.();
    // navigate('/login')
  }, []);

  //

  const userNavigation = [
    // { name: "Your Profile", href: "#" },
    // { name: "Settings", href: "#" },
    { name: "Sign out", href: "/login", onClick: handleLogout },
  ];

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-indigo-600">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 text-lg font-medium text-slate-300">
                      <Link to={"/"}>
                        <Logo />
                      </Link>
                    </div>
                    {isAuthenticated && (
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-indigo-700 text-white"
                                  : "text-white hover:bg-indigo-500 hover:bg-opacity-75",
                                "rounded-md px-3 py-2 text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="hidden md:block">
                    {isAuthenticated && (
                      <div className="ml-4 flex items-center md:ml-6">
                        <button
                          type="button"
                          className="rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                        >
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-indigo-600 text-sm text-white ">
                              {" "}
                              {/*focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600*/}
                              <span className="sr-only">Open user menu</span>
                              {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                              <ClientImage />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <Link
                                      to={item.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                      onClick={item?.onClick}
                                    >
                                      {item.name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    )}
                    {!isAuthenticated && (
                      <div className="ml-4 flex items-center md:ml-6">
                        <button
                          type="button"
                          className="text-white hover:bg-indigo-500 hover:bg-opacity-75"
                        >
                          <Link
                            to="/login"
                            className="rounded-md px-3 py-2 text-sm font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
                          >
                            Login
                          </Link>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {isAuthenticated && (
                      <>
                        {/* Mobile menu button */}
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                {isAuthenticated && (
                  <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-indigo-700 text-white"
                            : "text-white hover:bg-indigo-500 hover:bg-opacity-75",
                          "block rounded-md px-3 py-2 text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                )}
                {isAuthenticated && (
                  <div className="border-t border-indigo-700 pt-4 pb-3">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">
                          {user.name}
                        </div>
                        <div className="text-sm font-medium text-indigo-300">
                          {user.email}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full border-2 border-transparent bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
                          onClick={item?.onClick}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};
