import "../../styles/PieChartLegends.scss";
import { getFormattedValue } from "../../helpers/numbers";

interface IProps {
  data: {
    labels: string[] | undefined;
    datasets: any;
  };
}

export default function PieChartLegends(props: IProps) {
  return (
    <>
      {props.data.datasets.length && (
        <div className="main-wrapper">
          <div className="overflow">
            <div className="flex-container">
              <div>
                <ul className="mt-8">
                  {props.data.labels &&
                    props.data.labels.map((brand: string, i: number) => {
                      return (
                        <li key={i} className="listItemStyle">
                          <span className="brandText">{brand}</span>
                          <div
                            className="legendBox"
                            style={{
                              backgroundColor:
                                props.data.datasets[0].backgroundColor[i],
                            }}
                          />
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div>
                <ul className="values">
                  {props.data.datasets[0].data.map(
                    (firstPeriod: number, i: number) => (
                      <span key={i} className="value brandText">
                        {getFormattedValue(firstPeriod, "number", {
                          expanded: true,
                          round: true,
                        })}
                      </span>
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className="flex-container">
              <div>
                <ul className="mt-9">
                  {props.data.datasets[1].backgroundColor.map(
                    (color: string, i: any) => {
                      return (
                        <li key={i} className="listItemStyle">
                          <div
                            style={{
                              marginRight: "0px",
                              width: "20px",
                              height: "20px",
                              backgroundColor:
                                props.data.datasets[1].backgroundColor[i],
                            }}
                          />
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>

              <div>
                <ul className="values">
                  {props.data.datasets[1].data.map(
                    (secondPeriod: number, i: number) => (
                      <p key={i} className="value">
                        {getFormattedValue(secondPeriod, "number", {
                          expanded: true,
                          round: true,
                        })}
                      </p>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
