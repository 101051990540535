import { SideBarDef } from "ag-grid-community";

export const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: false,
        suppressValues: true,
        suppressPivotMode: true,
      },
    },
  ],
  defaultToolPanel: "",
};

export const sideBarWithFilter: SideBarDef = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: false,
        suppressValues: true,
        suppressPivotMode: true,
      },
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: true,
      },
    },
  ],
  defaultToolPanel: "",
};

export const sideBarWithFilterOpen: SideBarDef = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: false,
        suppressValues: false,
        suppressPivotMode: false,
      },
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
      toolPanelParams: {
        suppressExpandAll: false,
        suppressFilterSearch: false,
      },
    },
  ],
  defaultToolPanel: "",
};
