import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import GetBigqueryData from "../../config/hooks/bigquery";
import { getQueryConfig } from "../../config/bigquery/query-configs/index";
import { AgGridReact } from "ag-grid-react";
import { getColumnDefs } from "../../components/tables/tableDefs";
import { sideBarWithFilterOpen } from "../../components/tables/commonTableConfig";
import { aggFuncs } from "../../components/tables/aggregators";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { Grid, GridOptions } from "ag-grid-community";
import SpinnerProps from "./Spinner";
import { FilterContext } from "../../store/Filter-context";

interface Props {
    queryName: string;
    ERP: string;
    currency: string;
    //   setFullScreen: (status: boolean) => void;
}

function SalesSummaryTable(props: Props) {
    const filterCtx = useContext(FilterContext);
    const salesFilters = filterCtx.filtersSales;

    const gridRef: any = useRef();
    const gridQueryConfig = getQueryConfig(
        props.queryName,
        props.ERP,
        "Week",
        salesFilters
    );
    const { queryData: agGridQueryData, isLoading: isAgGridQueryDataLoading } =
        GetBigqueryData(gridQueryConfig);

    let {
        defaultColDef: defaultColDef,
        columnDefs: columnDefsAll,
        autoGroupColumnDef: autoGroupColumnDefAll,
    } = getColumnDefs(props.queryName, props.currency, props.ERP);

    const [agRef, setAgRef] = useState<any>();

    const onGridReady = (params: any) => {
        setAgRef(params.api);
        // params.api.showLoadingOverlay();
        params.columnApi!.setPivotMode(true);
        params.columnApi!.applyColumnState({
            state: [
                { colId: "customer", rowGroup: true },
                { colId: "itemClass", rowGroup: true },
                { colId: "sku", rowGroup: true },
            ],
        });
    };
    return (
        <>
            {!isAgGridQueryDataLoading ? (
                <div className={`ag-theme-balham mb-4 mt-2 h-[40rem]`}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={agGridQueryData}
                        rowSelection="multiple"
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefsAll}
                        sideBar={sideBarWithFilterOpen}
                        onGridReady={onGridReady}
                        // onFirstDataRendered={onFirstDataRendered}
                        autoGroupColumnDef={autoGroupColumnDefAll}
                        aggFuncs={aggFuncs}
                        groupDefaultExpanded={1}
                        suppressAggFuncInHeader={true}
                        overlayLoadingTemplate={"<span>Loading...</span>"}
                        overlayNoRowsTemplate={"<span>No rows</span>"}
                        animateRows={true}
                        suppressContextMenu={false}
                        tooltipShowDelay={0}
                        tooltipHideDelay={2000}
                        groupIncludeTotalFooter={true}
                    />
                </div>
            ) : (
                <div className="mt-10 flex h-[40rem] flex-1 flex-col items-center ">
                    <SpinnerProps
                        height="250"
                        width="auto"
                        className="flex-auto justify-center"
                    >
                        <span>Loading...</span>
                    </SpinnerProps>
                </div>
            )}
        </>
    );
}

export default SalesSummaryTable;
