import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./config/context/AuthContext";
import { FilterProvider } from "./store/FilterProvider";
import { UserProvider } from "./store/UserProvider";
import { LicenseManager } from "ag-grid-enterprise";
import { AG_GRID_LICENSE } from "./config/constants";
// import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

interface TagManagerArgs {
  gtmId: string;
  auth?: string;
  preview?: string;
}

const tagManagerArgs: TagManagerArgs = {
  gtmId: "GTM-TH4ZLVJ",
};

const isProduction = window.location.hostname === "app.thedashforward.com";
const isLocalDev = window.location.hostname === "localhost";

if (isProduction) {
  tagManagerArgs.auth = "uF6ZBJxjVZT2zs1VgROY9Q";
  tagManagerArgs.preview = "env-15";
} else {
  tagManagerArgs.auth = "G2cxkqe9A48zuWLGtwYZHA";
  tagManagerArgs.preview = "env-16";
}

TagManager.initialize(tagManagerArgs);
// ReactGA.initialize("G-6WM0C10LJ7");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <AuthProvider>
        <UserProvider>
          <FilterProvider>
            <App />
          </FilterProvider>
        </UserProvider>
      </AuthProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
