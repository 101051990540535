// Date Ranges are split into two sets. Init refers to the first date range. Compare is the second.
export const dateZones = {
  init: "init",
  compare: "compare",
};

export const filterOrder = [
  "division",
  "brand",
  "franchise",
  "category",
  "subcategory",
];

// There are three date range filters, each need a unique Identifier
export const dateRangeFilterTypes = {
  standard: "standard",
  trailing: "trailing",
  custom: "custom",
};

export const notificationListItemOptions = [5, 10, 15];

export const notificationGroups = {
  SALES_EXECUTION: "SALES_EXECUTION",
  OPERATIONS_EXECUTION: "OPERATIONS_EXECUTION",
  MEDIA_EXECUTION: "MEDIA_EXECUTION",
};

export const notificationTypes = {
  ORDERED_REVENUE: "orderedRevenueNotification",
  AMS_INDICATOR: "amsIndicatorNotification",
  WEEK_OF_COVER: "weekOfCoverNotification",
  CPC: "cpcNotification",
  AMS_ROAS_BRANDED: "amsRoasBrandedNotification",
  AMS_ROAS_COMPETITIVE: "amsRoasCompetitiveNotification",
  AMS_ROAS_GENERIC: "amsRoasGenericNotification",
  CHARGEBACKS: "chargebacksNotification",
  LOW_MARGIN_ASINS_W_HIGH_ACOS: "lowMarginAsinsWHighACOSNotification",
  REP_OOS: "RepOutOfStockNotification",
};

export const NOTIFICATION_ROW_LIMIT = 15;

const USD = "$";
const CAD = "C$";
const GBP = "£";
const EURO = "€";

export const currencies: {
  [country: string]: { currency: string; symbol: string; domain: string };
} = {
  US: { currency: "dollar", symbol: USD, domain: ".com" },
  UK: { currency: "pound", symbol: GBP, domain: ".co.uk" },
  DE: { currency: "euro", symbol: EURO, domain: ".de" },
  IT: { currency: "euro", symbol: EURO, domain: ".it" },
  ES: { currency: "euro", symbol: EURO, domain: ".es" },
  FR: { currency: "euro", symbol: EURO, domain: ".fr" },
  CA: { currency: "canadianDollar", symbol: CAD, domain: ".ca" },
};

export interface ICurrencySymbols {
  dollar: string;
  pound: string;
  euro: string;
  canadianDollar: string;
}
export const currencySymbols = (currency: string) => {
  const currencies = {
    dollar: USD,
    pound: GBP,
    euro: EURO,
    canadianDollar: CAD,
  };
  return currencies[currency as keyof ICurrencySymbols];
};

export const CompetitiveIntelligenceTabs = {
  COMPETITIVE_INTELLIGENCE_SUMMARY: "COMPETITIVE_INTELLIGENCE_SUMMARY",
  PRODUCT_DETAILS: "PRODUCT_DETAILS",
  PRICE_BAND: "PRICE_BAND",
  GROWTH: "GROWTH",
  BRAND_SNAPSHOT: "BRAND_SNAPSHOT",
  FLEXIBLE_DATA: "FLEXIBLE_DATA",
};

// NOTE: this value is exposed in the browser and therefore not a secret. It's safe to check into version control.
export const AG_GRID_LICENSE =
  "ag-Grid_Evaluation_License_Not_for_Production_100Devs30_August_2037__MjU4ODczMzg3NzkyMg==9e93ed5f03b0620b142770f2594a23a2";
