import { map, uniq, cloneDeep, isNil, keysIn } from "lodash";
import { getChartTooltip } from "../../helpers/chartTooltip";
import { cleanChartData } from "../../helpers/data";
import { getFormattedValue } from "../../helpers/numbers";
// import { FilterState } from '../store/Filter-context';
import { getColor } from "../colors";
// import { getFilterSelectionsByType, ISelectOption, getAllCategories } from './filters';
// import { currencies, currencySymbols } from "./constants";
interface IDataRow {
  [key: string]: any;
}

interface IProps {
  chartBoxLabel: string;
  chartTitle: string;
  dataRows: IDataRow[];
  currency: string;
  // filterHierarchy: ISelectOption[];
  // globalFilter: FilterState;
}

interface ChartAttributes {
  labels?: string[];
  dataSetConfigs: any;
  chartOptions?: any;
}

const emptyChartAttributes = {
  labels: [],
  dataSetConfigs: [],
  chartOptions: {},
};

interface YAxisTypes {
  [yAxisID: string]: string;
}

const getAxisPosition = (pos: number, len: number) => {
  const half = Math.ceil(len / 2);
  if (pos < half) {
    return "left";
  }
  return "right";
};

const defaultChartDataLabelFormatter =
  (yAxes: any) => (value: any, context: any) => {
    const datasetType = yAxes[context?.dataset?.yAxisID];
    return getFormattedValue(value, datasetType, { round: true });
  };

export const getMultiAxisChartOptions = (yAxes: YAxisTypes) => {
  const scales: { [yAxisID: string]: any } = {};
  Object.entries(yAxes).forEach(
    ([yAxisID, yAxisType]: [string, string], i, arr) => {
      scales[yAxisID] = {
        type: "linear",
        display: true,
        position: getAxisPosition(i, arr.length),
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: number) {
            return getFormattedValue(value, yAxisType, { round: true });
          },
        },
        title: {
          display: !["y", "y1"].includes(yAxisID),
          text: yAxisID,
        },
      };
      if (i !== 0) {
        scales[yAxisID].grid = {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        };
      }
    }
  );

  return {
    plugins: {
      datalabels: {
        display: "auto",
        align: "end",
        anchor: "end",
        color: (context: any) => {
          if (context.dataset.type === "line") {
            return "#1A3547";
          }
          return "#3A5161";
        },
        font: (context: any) => {
          if (context.dataset.type === "line") {
            return {
              weight: "bold",
            };
          }
        },
        formatter: defaultChartDataLabelFormatter(yAxes),
      },
      legend: {
        display: true,
      },
      // @ts-ignore
      tooltip: getChartTooltip(yAxes),
    },
    scales,
  };
};

const getStackedChartOptions = (valueType: string) => ({
  fill: true,
  responsive: true,
  plugins: {
    datalabels: null,
    // @ts-ignore
    tooltip: getChartTooltip({ y: valueType }),
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: number) {
          return getFormattedValue(value, valueType, { round: true });
        },
      },
    },
  },
});

const getAreaChartOptions = (valueType: string) => ({
  fill: true,
  responsive: true,
  plugins: {
    datalabels: null,
    // @ts-ignore
    tooltip: getChartTooltip({ y: valueType }),
  },
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: number) {
          return getFormattedValue(value, valueType, { round: true });
        },
      },
    },
  },
});

const getHorizontalBarChartOptions = (valueType: string) => ({
  fill: true,
  maintainAspectRatio: false,
  responsive: true,
  indexAxis: "y",
  plugins: {
    datalabels: null,
    // @ts-ignore
    tooltip: getChartTooltip({ y: valueType }),
  },
  scales: {
    x: {
      stacked: false,
      ticks: {
        callback: function (value: number) {
          return getFormattedValue(value, valueType, { round: true });
        },
      },
    },
    y: {
      stacked: false,
    },
  },
});

const removeEmptyDatasets = (dataset: any) => {
  const data: any[] = dataset?.data;
  const isListEmpty = data.every(isNil);
  return !isListEmpty;
};

const defaultDataSetConfigProps = {
  type: "bar",
  borderRadius: 10,
  borderWidth: 2,
  yAxisID: "y",
  order: 1,
};

export const dearChartAttributes = (props: IProps): ChartAttributes => {
  let cleanDataRows = cleanChartData(cloneDeep(props.dataRows));
  let attributesObject: { [chartTitle: string]: () => ChartAttributes } = {};

  if (props.chartBoxLabel === "summary-executive") {
    attributesObject = {
      "Sales over Time": () => {
        return {
          labels: map(cleanDataRows, "timePeriod"),
          dataSetConfigs: [
            {
              ...defaultDataSetConfigProps,
              label: "Gross Sales",
              order: 1,
              backgroundColor: "#6366f1",
              borderColor: "#6366f1",
              data: map(cleanDataRows, "grossSales"),
            },
            {
              ...defaultDataSetConfigProps,
              label: "Gross Sales LY",
              order: 1,
              backgroundColor: "#a5b4fc",
              borderColor: "#a5b4fc",
              data: map(cleanDataRows, "grossSalesLY"),
            },
            {
              ...defaultDataSetConfigProps,
              label: "Gross Quantity",
              type: "line",
              order: 0,
              backgroundColor: "#71717a",
              borderColor: "#71717a",
              data: map(cleanDataRows, "grossQuantity"),
              yAxisID: "y1",
            },
            {
              ...defaultDataSetConfigProps,
              label: "Gross Quantity LY",
              type: "line",
              order: 0,
              backgroundColor: "#d4d4d8",
              borderColor: "#d4d4d8",
              data: map(cleanDataRows, "grossQuantityLY"),
              yAxisID: "y1",
            },
          ],
          chartOptions: getMultiAxisChartOptions({
            y: props.currency,
            y1: "number",
          }),
        };
      },
      "Customer Sales": () => {
        // let divisions = getFilterSelectionsByType('division', props.filterHierarchy);
        const months = uniq(map(cleanDataRows, "timePeriod"));
        let categoryNames = uniq(keysIn(cleanDataRows[0]["grossSales"]));
        // let divisions = uniq(map(cleanDataRows, 'division'));
        // console.log(cleanDataRows);
        // If division selected in the global filter then
        // Display Legends for selected divisions only
        // if (props.globalFilter.filters.division && props.globalFilter.filters.division.length) {
        //   const filterDivisions = props.globalFilter.filters.division.map((item: any) => item.label);
        //   const filteredDivisions = divisions.filter((division: any) => filterDivisions.includes(division.label));
        //   divisions = filteredDivisions;
        // }
        return {
          labels: months,
          dataSetConfigs: categoryNames.map((categoryNames: any, i: number) => {
            const data = cleanDataRows.map(
              (r: any) => r["grossSales"][categoryNames]
            );
            return {
              ...defaultDataSetConfigProps,
              label: categoryNames,
              type: "bar",
              data,
              backgroundColor: getColor("index", i.toString()),
              borderColor: getColor("index", i.toString()),
            };
          }),
          chartOptions: getStackedChartOptions(props.currency),
        };
      },
      "Product Sales": () => {
        // let divisions = getFilterSelectionsByType('division', props.filterHierarchy);
        const months = uniq(map(cleanDataRows, "timePeriod"));
        // let divisionsconfig = getQueryConfigs(['divsisionsQuery']);
        // let [divisionsData, isLoadingDivision] = GetBigqueryData(divisionsconfig['divsisionsQuery'])
        // let cleanDataRowsDivison = cleanChartData(cloneDeep(divisionsData));
        // let classNames = uniq(map(cleanDataRows, ['grossItemRevenue']['class']));
        let classNames = uniq(keysIn(cleanDataRows[0]["grossItemSales"]));
        // console.log(classNames);
        // // If division selected in the global filter then
        // // Display Legends for selected divisions only
        // if (props.globalFilter.filters.division && props.globalFilter.filters.division.length) {
        //   const filterDivisions = props.globalFilter.filters.division.map((item: any) => item.label);
        //   const filteredDivisions = divisions.filter((division: any) => filterDivisions.includes(division.label));
        //   divisions = filteredDivisions;
        // }

        return {
          labels: months,
          dataSetConfigs: classNames.map((classNames: any, i: number) => {
            const data = cleanDataRows.map(
              (r: any) => r["grossItemSales"][classNames]
            );
            return {
              ...defaultDataSetConfigProps,
              label: classNames.replaceAll("_", " "),
              type: "bar",
              data,
              backgroundColor: getColor("index", i.toString()),
              borderColor: getColor("index", i.toString()),
            };
          }),
          chartOptions: getStackedChartOptions(props.currency),
        };
      },
    };
  } else if (!Object.keys(attributesObject).includes(props.chartTitle)) {
    throw new Error(`invalid chart name provided ${props.chartTitle}`);
  }
  if (!cleanDataRows?.length) {
    return emptyChartAttributes;
  }
  return attributesObject[props.chartTitle]();
};
