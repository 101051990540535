import React from "react";

interface ButtonGroupProps {
  title: string;
  buttons: string[];
  value: string;
  onChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPeriod: string
  ) => void;
}

function PeriodToggleButtons(props: ButtonGroupProps) {
  return (
    <div
      className="bg-slate-25 inline-flex justify-self-start rounded-md shadow-sm"
      role="group"
    >
      {" "}
      {/*onChange={() => props.onChange}*/}
      {props.buttons.map((name, index) => {
        let buttonMargin = "";
        if (index === 0) {
          buttonMargin = "m-2 ";
        } else buttonMargin = "mt-2 mb-2 mr-2 ";
        let activeClass = "";
        if (props.value === name) {
          activeClass = "bg-slate-200 ";
        }
        return (
          <button
            type="button"
            onClick={(e) => props.onChange(e, name)}
            className={
              buttonMargin +
              activeClass +
              "rounded-md px-2 py-1 text-sm font-medium transition duration-150 ease-in-out hover:bg-slate-100"
            }
            key={index}
          >
            {name}
          </button>
        );
      })}
    </div>
  );
}

export default PeriodToggleButtons;
