import { createContext, useState, useEffect, ReactNode } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
// import { useLocalStorage, useGetLocalStorage } from "../hooks/useLocalStorage"; // its getting error for me. import it

export const AuthContext = createContext<ContextData | null>(null);

// interface AuthToken {
//   name: string;
//   exp: number;
// }

interface AuthProps {
  children: ReactNode;
}

// interface and types, enums, always PascalCase
export interface UserInterface {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  username: string;
  email: string;
}

type RegisterUser = {
  username: string;
  password: string;
  confirmPassword: string;
};

interface ContextData {
  user: UserInterface | null;
  setUser: (value: UserInterface) => void;
  authTokens: any;
  setAuthTokens: (authToken: string) => void;
  registerUser: (arg: RegisterUser) => void;
  loginUser: (username: string, password: string) => void;
  logoutUser: () => void;
}

// avoid passing props named props. If possible, destructuring them.
export const AuthProvider = ({ children }: AuthProps) => {
  const baseURL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [user, setUser] = useState<UserInterface | null>(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens") || "{}")
      : null
  );

  const [authTokens, setAuthTokens] = useState<any | null>(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens") || "{}")
      : null
  );

  // you can extract this state. I cannot understand well, but if you get time. Would be better to read, for yourself for long.

  // I think this is it. Install dotenv, e create a file name .env and inside it create BASE_URL=http://127.0.0.1:8000/api/

  // then here you create a const TOKEN_URL = processs.env.BASE_URL
  // in your fetch you pass fetch(TOKEN_URL)

  // that makes a ton of sense

  async function loginUser(username: string, password: string) {
    const response = await fetch(baseURL + "/api/token/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });
    const data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      navigate("/");
    } else {
      alert("Something went wrong on login!");
    }
  }

  // you can do a folder named services and extract these things into it.

  // you can create a const BASE_URL and get it from process.env and pass into fetch.

  //and here you pass REGISTER_URL

  async function registerUser({
    username,
    password,
    confirmPassword,
  }: RegisterUser) {
    const response = await fetch(baseURL + "/api/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        confirmPassword,
      }),
    });
    if (response.status === 201) {
      navigate("/login");
    } else {
      alert("Something went wrong!");
    }
  }

  function logoutUser() {
    localStorage.removeItem("authTokens");
    setAuthTokens(null);
    setUser(null);
  }

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  const contextData: ContextData = {
    user: user,
    setUser: setUser,
    authTokens: authTokens,
    setAuthTokens: setAuthTokens,
    registerUser: registerUser,
    loginUser: loginUser,
    logoutUser: logoutUser,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
