import { IAggFuncParams, IRowNode } from "ag-grid-enterprise";
import { isNil } from "lodash";
import { ICombinedCellRendererParamsColumn } from "./cellRenderers";
import { ValueGetterParams, ValueFormatterParams } from "ag-grid-enterprise";

/**
 * getSum calculates sum for a particular row group in the table.
 * For example, suppose the table has nested row groups such that the user can "drill down"
 * from division -> brand -> franchise -> category -> subcategory -> ASIN level. As the
 * user drills down, we show row group totals for each level in this nested row group hierarcy.
 * This function calculates aggregations for each metric at each level as the user drills down in the table.
 */
function getSum(rows: IRowNode[], colName?: string) {
    if (!colName) {
        return 0;
    }
    const allColumnValues = rows.map((node: IRowNode) => node.data[colName]);
    return allColumnValues
        .filter((v) => v)
        .reduce((acc: any, elt: any) => acc + elt, 0);
}

function createValueObject(thisYear: number, lastYear: number) {
    return {
        thisYear: thisYear,
        lastYear: lastYear,
        toString: () => `${thisYear && lastYear ? thisYear / lastYear - 1 : 0}`,
    };
}

export function GrossSalesFunc(params: IAggFuncParams) {
    let thisYear = 0;
    let lastYear = 0;

    // console.log(params.values);

    params.values.forEach((value) => {
        if (value && value.thisYear) {
            thisYear += value.thisYear;
        }
        if (value && value.lastYear) {
            lastYear += value.lastYear;
        }
    });
    return createValueObject(thisYear, lastYear);
}

export function GrossSalesValueGetter(params: ValueGetterParams) {
    if (!(params.node && params.node.group)) {
        // no need to handle group levels - calculated in the 'ratioAggFunc'
        return createValueObject(
            params.data!.grossSales,
            params.data!.grossSalesLY
        );
    }
}

export function ratioFormatter(params: ValueFormatterParams) {
    if (!params.value || params.value === 0) return "";
    return "" + Math.round(params.value * 100) / 100;
}

function GrossSalesYOY(rows: IRowNode[]) {
    const grossSales = getSum(rows, "grossSales");
    const grossSalesLY = getSum(rows, "grossSalesLY");
    if (grossSales === 0 && grossSalesLY != 0) {
        return -1;
    }
    if (grossSalesLY === 0 && grossSales != 0) {
        return 0;
    }
    return grossSales / grossSalesLY - 1;
}

function NetSalesYOY(rows: IRowNode[]) {
    const netSales = getSum(rows, "netSales");
    const netSalesLY = getSum(rows, "netSalesLY");
    return netSales / netSalesLY - 1;
}

function GrossProductMarginYOY(rows: IRowNode[]) {
    const grossProfit = getSum(rows, "grossProfit");
    const grossProfitLY = getSum(rows, "grossProfitLY");
    return grossProfit / grossProfitLY - 1;
}

function GrossQuantityYOY(rows: IRowNode[]) {
    const grossQuantity = getSum(rows, "grossQuantity");
    const grossQuantityLY = getSum(rows, "grossQuantityLY");
    return grossQuantity / grossQuantityLY - 1;
}

function AvgSales(rows: IRowNode[]) {
    const grossSales = getSum(rows, "grossSales");
    const numberOfOrders = getSum(rows, "numberOfOrders");
    return grossSales / numberOfOrders;
}

function AvgQuantity(rows: IRowNode[]) {
    const grossQuantity = getSum(rows, "grossQuantity");
    const numberOfOrders = getSum(rows, "numberOfOrders");
    return grossQuantity / numberOfOrders;
}

function NetPPMFromAmazon(rows: IRowNode[]) {
    const secondPeriodAmzMargin = getSum(rows, "second_period_amzr_margin");
    const secondPeriodShippedRev = getSum(
        rows,
        "second_period_shipped_revenue"
    );
    return secondPeriodAmzMargin / secondPeriodShippedRev;
}

/**
 * Unlike getSum, getColumnTotalSum calculates entire column total, not just
 * total for one row group.
 */
function getColumnTotalSum(rows: IRowNode[], colName?: string) {
    if (!colName || !rows.length) {
        return 0;
    }
    const allColumnValues: number[] = [];
    // @ts-ignore
    const gridApi = rows[0]?.beans?.gridApi || rows[0]?.api;
    gridApi.forEachNode((node: any) => {
        if (node?.data?.[colName]) {
            allColumnValues.push(node.data[colName]);
        }
    });
    return allColumnValues
        .filter((v) => v)
        .reduce((acc: any, elt: any) => acc + elt, 0);
}
export function aggregateColumn(
    rows: IRowNode[],
    column: ICombinedCellRendererParamsColumn
) {
    let result: number | null = 0;
    try {
        const aggFuncs: { [aggFunc: string]: (rows: IRowNode[]) => number } = {
            NetPPMFromAmazon,
            AvgQuantity,
            AvgSales,
            GrossSalesYOY,
            NetSalesYOY,
            GrossQuantityYOY,
            GrossProductMarginYOY,
        };
        if (Object.keys(aggFuncs).includes(column.aggFunc)) {
            result = aggFuncs[column.aggFunc](rows);
        } else {
            result = getSum(rows, column.name);
        }
    } catch (e) {
        // avoid division by 0
        console.log(e);
        result = null;
    } finally {
        if (
            Number.isNaN(result) ||
            result === Infinity ||
            result === -Infinity
        ) {
            result = null;
        }
        return result;
    }
}

const isWOBField = (field?: string) => {
    const WOBFields = ["loreal_share_cy", "non_loreal_share_cy"];
    return field && WOBFields.includes(field);
};

export const aggFuncs = {
    // this overrides the grids built-in sum function
    combinedValsAggregator: (params: IAggFuncParams) => {
        const { columns } = params.colDef.cellRendererParams;
        return columns.map((column: ICombinedCellRendererParamsColumn) => {
            const result = aggregateColumn(
                params.rowNode.allLeafChildren,
                column
            );
            return { ...column, result };
        });
    },
    singleValsAggregator: (params: IAggFuncParams) => {
        const { columns } = params.colDef.cellRendererParams;
        return columns.map((column: ICombinedCellRendererParamsColumn) => {
            const result = aggregateColumn(
                params.rowNode.allLeafChildren,
                column
            );
            return result;
        });
    },
    /**
     * This custom sum aggregation function is used by columns that roll up to the
     * division level, but shouldn't be summed across divisions. For example, 'WOB Ordered Revenue'.
     * If we use default AGGrid sum, this would show as 400% in the total footer because
     * each division sums to 100%. This way, we don't show an incorrect value in the total
     * footer. The "result > 2" threshold allows the total to go up to 200%. In theory
     * each division should only sum to 100%, but this allows some margin for error so if there are
     * odd values over 100%, users can report them.
     */
    percentSumNoGrandTotal: (params: IAggFuncParams) => {
        const result = aggregateColumn(params.rowNode.allLeafChildren, {
            name: params.colDef.field,
            type: "",
            aggFunc: "",
        });
        if (isNil(result) || result > 2 || isWOBField(params.colDef.field)) {
            return null;
        }
        return result;
    },
};
