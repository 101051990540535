import { ReactElement, useContext } from "react";
import { Navigate } from "react-router-dom";
import Home from "./views/Home";
import SalesSummary from "./views/SalesSummary";
import NotFound from "./views/Home";
// import Unauthorized from "./views/Home";
import Login from "./views/Login";
import { UserContext } from "./store/User-context";
import TagManager from "react-gtm-module";

const Routes = (isLoggedIn: boolean) => {
  const userCtx = useContext(UserContext);

  const routeGuard = (view: ReactElement, isLoggedIn: boolean) => {
    const is_enabled = userCtx?.user?.profile?.is_enabled;
    if (isLoggedIn) {
      if (!is_enabled) {
        return <Home />;
      }
      return view;
      //TODO: uncomment to enable user Permissions
      // if (userHasPermission(viewPermission)) {
      //   return view;
      // } else {
      //   return <Unauthorized />;
      // }
    }
    return <Navigate to="/login" />;
  };

  return [
    {
      path: "/sales",
      element: routeGuard(<SalesSummary />, isLoggedIn),
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: routeGuard(<Home />, isLoggedIn),
      children: [{ path: "home", element: routeGuard(<Home />, isLoggedIn) }],
    },
    {
      path: "*",
      element: isLoggedIn ? <NotFound /> : <Navigate to="/login" />,
    },
  ];
};

export default Routes;
