import numbro from "numbro";
import { currencies } from "../config/constants";

interface ICurrencyFormatters {
  euro?: any;
  dollar?: any;
  canadianDollar?: any;
  pound?: any;
}

export const toReadableNumber = (
  val: number,
  round?: boolean,
  expanded?: boolean
) => {
  let finalVal = val;
  let numFormat = "0.00a";
  if (expanded && round) {
    finalVal = Math.round(val);
    numFormat = "0,0";
  } else if (expanded) {
    numFormat = "0,0";
  } else if (round) {
    numFormat = "0.0a";
  }
  return numbro(finalVal).format(numFormat).toUpperCase();
};
export const toReadablePercentage = (
  val: number,
  round?: boolean,
  expanded?: boolean
) => `${toReadableNumber(val * 100, round, expanded)} %`;
export const toReadableDollar = (
  val: number,
  round?: boolean,
  expanded?: boolean
) => `$ ${toReadableNumber(val, round, expanded)}`;
export const toReadableCanadianDollar = (
  val: number,
  round?: boolean,
  expanded?: boolean
) => `C$ ${toReadableNumber(val, round, expanded)}`;
export const toReadablePound = (
  val: number,
  round?: boolean,
  expanded?: boolean
) => `£ ${toReadableNumber(val, round, expanded)}`;
export const toReadableEuro = (
  val: number,
  round?: boolean,
  expanded?: boolean
) => `€ ${toReadableNumber(val, round, expanded)}`;

export function toReadableCurrency(
  country: string,
  val: number,
  round?: boolean,
  expanded?: boolean
) {
  const currencyFormatters = {
    euro: toReadableEuro,
    dollar: toReadableDollar,
    canadianDollar: toReadableCanadianDollar,
    pound: toReadablePound,
  };

  const currencyFormatter =
    currencyFormatters[
      currencies[country].currency as keyof ICurrencyFormatters
    ];
  return currencyFormatter(val, round, expanded);
}

export const getFormattedValue = (
  value: number | null | undefined,
  numType: string,
  options?: { round?: boolean; expanded?: boolean }
): string => {
  if (value === null || value === undefined) {
    return "";
  }
  const numTypeMap: {
    [t: string]: (n: number, round?: boolean, expanded?: boolean) => string;
  } = {
    number: toReadableNumber,
    percent: toReadablePercentage,
    dollar: toReadableDollar,
    canadianDollar: toReadableCanadianDollar,
    pound: toReadablePound,
    euro: toReadableEuro,
  };
  return numTypeMap[numType](
    value,
    options?.round,
    options?.expanded
  ) as string;
};

// export const numTypeMap = {
//   number: toReadableNumber,
//   percent: toReadablePercentage,
//   dollar: toReadableDollar,
//   canadianDollar: toReadableCanadianDollar,
//   pound: toReadablePound,
//   euro: toReadableEuro,
// } as const;

// export const getFormattedValue = (
//   value: number | null | undefined,
//   numType: keyof typeof numTypeMap,
//   options?: { round?: boolean; expanded?: boolean }
// ): string => {
//   if (value === null || value === undefined) {
//     return '';
//   }

//   return numTypeMap[numType](value, options?.round, options?.expanded) as string;
// };
