import { getNetsuiteScoreCardConfig } from "../../config/scorecards/netsuiteScorecards";
import { getDEARScoreCardConfig } from "../../config/scorecards/dearScorecards";
import {
  IDataRow,
  IScoreCardCalloutConfig,
} from "../../config/scorecards/scorecards";
import { cleanChartData } from "../../helpers/data";
import { ScoreCardSparkLineChart, ScoreCardBarChart } from "./ScoreCardChart";
import SpinnerProps from "./Spinner";

interface Props {
  title: string;
  queryData: IDataRow[];
  isLoading: boolean;
  currency: string;
  ERP: string;
}

// function ScoreCard(props: Props) {
function ScoreCard(props: Props) {
  const data = cleanChartData(props.queryData);
  let scoreCardConfig = null;

  switch (props.ERP) {
    case "Netsuite":
      scoreCardConfig = getNetsuiteScoreCardConfig(
        props.title,
        props.currency,
        data
      );
      break;
    case "DEAR":
      scoreCardConfig = getDEARScoreCardConfig(
        props.title,
        props.currency,
        data
      );
      break;
    default:
      scoreCardConfig = getNetsuiteScoreCardConfig(
        props.title,
        props.currency,
        data
      );
  }

  return (
    <div
      className={`min-w-180 max-w-220 flex flex-1 flex-col p-6 ${
        props.isLoading ? "flex-auto items-center justify-center" : ""
      }`}
    >
      {!props.isLoading ? (
        <div className="min-h-274">
          <h3 className="font-large text-left text-xl text-gray-900">
            {props.title}
          </h3>
          <h4 className="font-large mt-2 text-left text-2xl font-bold text-gray-900">
            {scoreCardConfig.KPIValue}
          </h4>
          <dl className="mt-1 flex flex-grow flex-col">
            {" "}
            {/*justify-between*/}
            {data && scoreCardConfig.chartType === "bar" ? (
              <ScoreCardBarChart data={scoreCardConfig} kpi={props.title} />
            ) : (
              <ScoreCardSparkLineChart
                data={scoreCardConfig}
                kpi={props.title}
              />
            )}
            {scoreCardConfig.callouts.map(
              (callout: IScoreCardCalloutConfig) => {
                const classNames: { [name: string]: any } = {
                  positive: "text-lime-600",
                  negative: "text-red-600",
                  neutral: "test-gray-900",
                };
                return (
                  <span
                    className={
                      classNames[callout.status] +
                      " text-md font-large text-left font-semibold"
                    }
                    key={callout.id}
                  >
                    {callout.value}
                  </span>
                  // {callout.status == 'positive' ? <GreenArrowIcon /> : null}
                  // {callout.status == 'negative' ? <WarningIcon /> : null}
                );
              }
            )}
          </dl>
        </div>
      ) : (
        <SpinnerProps height="50" width="auto">
          <span>Loading...</span>
        </SpinnerProps>
      )}
    </div>
  );
}

export default ScoreCard;
