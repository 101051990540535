export const indexColors: { [index: string]: string } = {
  0: "#FB7185",
  1: "#60A5FA",
  2: "#FACC15",
  3: "#38BDF8",
  4: "#FB923C",
  5: "#818CF8",
  6: "#A3E635",
  7: "#F87171",
  8: "#34D399",
  9: "#FBBF24",
  10: "#A78BFA",
  11: "#2DD4BF",
  12: "#F472B6",
  13: "#A8A29E",
  14: "#22D3EE",
  15: "#C084FC",
  16: "#4ADE80",
  17: "#E879F9",
  18: "#94A3B8",
  19: "#3F3F46",
};
