import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ISelectOptionSales } from "../../config/filters";
import SalesFilters from "./globalFilters/SalesFilters";
import { AdjustmentsHorizontalIcon as FilterIcon } from "@heroicons/react/24/outline";
import { IDateRange } from "./DateRangeFilters";

interface IProps {
  selectedFiltersSummary: (filters: IFilterSelectionsSales) => void;
  setDrawerStatus: (status: boolean) => void;
  filterDrawerStatus: boolean;
}

export interface IFilterSelectionsSales {
  customerCategory: ISelectOptionSales[];
  customer: ISelectOptionSales[];
  itemClass: ISelectOptionSales[];
  orderStatus: ISelectOptionSales[];
  sku?: string[];
  salesOrderID?: string[];
  dateRanges?: IDateRange;
}

const GlobalFilters = (props: IProps) => {
  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);

  useEffect(() => {
    setShowFilterDrawer(props.filterDrawerStatus);
  }, [props.filterDrawerStatus]);

  const handelFilterButtonClick = () => {
    setShowFilterDrawer(!showFilterDrawer);
    props.setDrawerStatus(false);
  };

  // const handelOnHide = () => {
  //   setShowFilterDrawer(false);
  //   props.setDrawerStatus(false);
  // };

  const displayFilters = () => {
    if (window.location.pathname.includes("sales")) {
      return (
        <SalesFilters
          selectedFiltersSummary={props.selectedFiltersSummary}
          setShowFilterDrawer={setShowFilterDrawer}
          setDrawerStatus={props.setDrawerStatus}
        />
      );
    }
  };

  return (
    <div className="">
      <button
        type="button"
        className="flex rounded-md bg-indigo-600 px-2 py-1 text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-indigo-800 focus:bg-indigo-800 focus:outline-none focus:ring-0 active:bg-indigo-800"
        onClick={handelFilterButtonClick}
      >
        <FilterIcon className="m-auto w-7" />
      </button>
      <Transition.Root show={showFilterDrawer} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setShowFilterDrawer}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll">
                        {" "}
                        {/* py-6  */}
                        <div className="bg-slate-200 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="flex py-6">
                              <FilterIcon className="mr-3 w-7" />
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Filters
                              </Dialog.Title>
                            </div>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none" //focus:ring-2 focus:ring-indigo-500
                                onClick={() => setShowFilterDrawer(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {displayFilters()}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default GlobalFilters;
