import GlobalFilters, { IFilterSelectionsSales } from "../forms/GlobalFilters";
// import DateRangeFilters from '../forms/DateRangeFilters';
import { useContext, useEffect, useState } from "react";
import { FilterContext } from "../../store/Filter-context";
import { initialFiltersSales } from "../forms/globalFilters/SalesFilters";
import FilterSummary from "../UI/FilterSummary";
import DatePicker from "../forms/elements/DatePicker";

interface IProps {
  layer: string;
  currentTab?: string;
  subTab?: any;
}

const FilterRow = (props: IProps) => {
  const filterCtx = useContext(FilterContext);
  const [filterSummary, setFilterSummary] = useState<IFilterSelectionsSales>(
    initialFiltersSales()
  );
  const [filterDrawerStatus, setFilterDrawerStatus] = useState<boolean>(false);

  // const currentPage = window.location.pathname; // TODO: Remove this when country data is available

  // Reset filters when switching between page types
  // if (currentPage.includes("sales")) {
  //   filterCtx.filtersSales = initialFiltersSales();
  // } else {
  //   filterCtx.filtersSales = initialFiltersSales();
  // }

  // Pass Data from child to parent component
  const updateSetFilterSummary = (object: any) => {
    setFilterSummary(object);
  };

  // Pass Data from child to parent component
  const updateFilterDrawerStatus = (status: boolean) => {
    setFilterDrawerStatus(status);
  };

  useEffect(() => {
    if (filterCtx.filtersSales) {
      setFilterSummary(filterCtx.filtersSales);
    }
  }, [filterCtx.filtersSales]);

  // const getDateRangeFilter = () => {
  //   if (currentPage.includes('competitive-intelligence')) {
  //     return <CompIntelFilter currentTab={props.currentTab || ''} subTab={props.subTab} />;
  //   } else if (currentPage.includes('media-performance')) {
  //     return <MediaPerformanceFilter />
  //   } else if (currentPage.includes('asin-opportunity-finder')) {
  //     return null;
  //   } else {
  //     return <DateRangeFilters layer={props.layer} />;
  //   }
  // };

  return (
    <div className="grid grid-flow-row-dense grid-cols-4">
      <div className="col-span-1 justify-start">
        <DatePicker />
      </div>
      <div className="col-span-3 flex flex-wrap justify-end">
        <GlobalFilters
          selectedFiltersSummary={updateSetFilterSummary}
          filterDrawerStatus={filterDrawerStatus}
          setDrawerStatus={updateFilterDrawerStatus}
        />
        <span className="m-2 pl-2 font-semibold">Filters</span>
      </div>
      <div className="col-span-4 flex justify-start py-1">
        <FilterSummary
          filterSummary={filterSummary}
          setFilterDrawerStatus={updateFilterDrawerStatus}
          filterDrawerStatus={filterDrawerStatus}
        />
      </div>
    </div>
  );
};

export default FilterRow;
