export const dearSalesFilters = `
SELECT
  DISTINCT customer,
  customerCategory,
  itemClass,
  sku,
  -- salesOrderID,
  orderStatus,
FROM
  \`<project>.<dataset>.sales_exec_summary_yoy\``;

export const dearScorecardSales = `WITH
t1 AS (
SELECT
  DATE(DATETIME_TRUNC(\`date\`, WEEK)) AS timePeriod,
  SUM(grossSales) AS grossSales,
  SUM(grossSalesLY) AS grossSalesLY,
  SUM(grossQuantity) AS grossQuantity,
  SUM(grossQuantityLY) AS grossQuantityLY,
  SUM(grossTax) AS grossTax,
  SUM(grossTaxLY) AS grossTaxLY,
  SUM(grossAdditionalCharges) AS grossAdditionalCharges,
  SUM(grossAdditionalChargesLY) AS grossAdditionalChargesLY,
  SUM(netSales) AS netSales,
  SUM(netSalesLY) AS netSalesLY,
  SUM(grossProfit) AS grossProfit,
  SUM(grossProfitLY) AS grossProfitLY,
  SUM(cogsAmount) AS cogsAmount,
  SUM(cogsAmountLY) AS cogsAmountLY,
  SUM(numberOfOrdersFlag) AS numberOfOrdersFlag,
  SUM(numberOfOrdersFlagLY) AS numberOfOrdersFlagLY,
FROM
  \`<project>.<dataset>.sales_exec_summary_yoy\`
<WHERE CLAUSE>
GROUP BY
  DATE(DATETIME_TRUNC(\`date\`, WEEK)))
SELECT
timePeriod,
numberOfOrdersFlag AS numberOfOrders,
SUM(IFNULL(numberOfOrdersFlag,0)) OVER (ORDER BY timePeriod ASC) AS numberOfOrdersTotal,
numberOfOrdersFlagLY AS numberOfOrdersLY,
SUM(IFNULL(numberOfOrdersFlagLY,0)) OVER (ORDER BY timePeriod ASC) AS numberOfOrdersTotalLY,
grossSales AS grossSales,
SUM(IFNULL(grossSales,0)) OVER (ORDER BY timePeriod ASC) AS grossSalesTotal,
grossSalesLY AS grossSalesLY,
SUM(IFNULL(grossSalesLY,0)) OVER (ORDER BY timePeriod ASC) AS grossSalesTotalLY,
grossQuantity AS grossQuantity,
SUM(IFNULL(grossQuantity,0)) OVER (ORDER BY timePeriod ASC) AS grossQuantityTotal,
grossQuantityLY AS grossQuantityLY,
SUM(IFNULL(grossQuantityLY,0)) OVER (ORDER BY timePeriod ASC) AS grossQuantityTotalLY,
grossTax AS grossTax,
SUM(IFNULL(grossTax,0)) OVER (ORDER BY timePeriod ASC) AS grossTaxTotal,
grossTaxLY AS grossTaxLY,
SUM(IFNULL(grossTaxLY,0)) OVER (ORDER BY timePeriod ASC) AS grossTaxTotalLY,
grossAdditionalCharges AS grossAdditionalCharges,
SUM(IFNULL(grossAdditionalCharges,0)) OVER (ORDER BY timePeriod ASC) AS grossAdditionalChargesTotal,
grossAdditionalChargesLY AS grossAdditionalChargesLY,
SUM(IFNULL(grossAdditionalChargesLY,0)) OVER (ORDER BY timePeriod ASC) AS grossAdditionalChargesTotalLY,
netSales AS netSales,
SUM(IFNULL(netSales,0)) OVER (ORDER BY timePeriod ASC) AS netSalesTotal,
netSalesLY AS netSalesLY,
SUM(IFNULL(netSalesLY,0)) OVER (ORDER BY timePeriod ASC) AS netSalesTotalLY,
cogsAmount AS cogsAmount,
SUM(IFNULL(cogsAmount,0)) OVER (ORDER BY timePeriod ASC) AS cogsAmountTotal,
cogsAmountLY AS cogsAmountLY,
SUM(IFNULL(cogsAmountLY,0)) OVER (ORDER BY timePeriod ASC) AS cogsAmountTotalLY,
grossProfit AS grossProfit,
SUM(grossProfit) OVER (ORDER BY timePeriod ASC) AS grossProfitTotal,
grossProfitLY AS grossProfitLY,
SUM(grossProfitLY) OVER (ORDER BY timePeriod ASC) AS grossProfitTotalLY,
SAFE_DIVIDE(grossSales, numberOfOrdersFlag) AS avgSales,
SAFE_DIVIDE(SUM(IFNULL(grossSales,0)) OVER (ORDER BY timePeriod ASC),SUM(IFNULL(numberOfOrdersFlag,0)) OVER (ORDER BY timePeriod ASC)) AS avgSalesTotal,
SAFE_DIVIDE(grossSalesLY, numberOfOrdersFlagLY) AS avgSalesLY,
SAFE_DIVIDE(SUM(IFNULL(grossSalesLY,0)) OVER (ORDER BY timePeriod ASC),SUM(IFNULL(numberOfOrdersFlagLY,0)) OVER (ORDER BY timePeriod ASC)) AS avgSalesTotalLY,
SAFE_DIVIDE(grossQuantity, numberOfOrdersFlag) AS avgQuantity,
SAFE_DIVIDE(SUM(IFNULL(grossQuantity,0)) OVER (ORDER BY timePeriod ASC),SUM(IFNULL(numberOfOrdersFlag,0)) OVER (ORDER BY timePeriod ASC)) AS avgQuantityTotal,
SAFE_DIVIDE(grossQuantityLY, numberOfOrdersFlagLY) AS avgQuantityLY,
SAFE_DIVIDE(SUM(IFNULL(grossQuantityLY,0)) OVER (ORDER BY timePeriod ASC),SUM(IFNULL(numberOfOrdersFlagLY,0)) OVER (ORDER BY timePeriod ASC)) AS avgQuantityTotalLY,
SAFE_DIVIDE(SAFE_DIVIDE(grossSales, numberOfOrdersFlag), SAFE_DIVIDE(grossQuantity, numberOfOrdersFlag)) AS avgPrice,
SAFE_DIVIDE(SAFE_DIVIDE(grossSalesLY, numberOfOrdersFlagLY), SAFE_DIVIDE(grossQuantityLY, numberOfOrdersFlagLY)) AS avgPriceLY
FROM
t1
GROUP BY
timePeriod,
grossSales,
grossSalesLY,
grossQuantity,
grossQuantityLY,
grossTax,
grossTaxLY,
grossAdditionalCharges,
grossAdditionalChargesLY,
netSales,
netSalesLY,
grossProfit,
grossProfitLY,
cogsAmount,
cogsAmountLY,
numberOfOrders,
numberOfOrdersLY;`;

export const dearRevenueChart = `
SELECT
  <SELECTED_PERIOD> AS timePeriod,
  SUM(grossSales) AS grossSales,
  SUM(grossSalesLY) AS grossSalesLY,
  SUM(grossQuantity) AS grossQuantity,
  SUM(grossQuantityLY) AS grossQuantityLY,
FROM
  \`<project>.<dataset>.sales_exec_summary_yoy\`
<WHERE CLAUSE>
GROUP BY
  timePeriod
ORDER BY
  timePeriod ASC`;

export const dearRevenueChartCustomer = `
DECLARE
category STRING;
SET
category = (
SELECT
  CONCAT('"', STRING_AGG(DISTINCT customerCategory, '", "'), '"')
FROM
  \`<project>.<dataset>.sales_exec_summary_yoy\`);

EXECUTE IMMEDIATE
FORMAT('''
WITH
t1 AS (
SELECT
  <SELECTED_PERIOD> AS timePeriod,
  customerCategory,
  SUM(grossSales) AS grossSales,
FROM
  \`<project>.<dataset>.sales_exec_summary_yoy\`
<WHERE CLAUSE>
GROUP BY
  timePeriod,
  customerCategory)
SELECT
timePeriod,
(select as struct T1.* except(timePeriod)) as grossSales
FROM
T1 PIVOT (SUM(IFNULL(grossSales,0)) FOR customerCategory in (%s)) T1
ORDER BY timePeriod ASC;''',category);`;

export const dearRevenueChartItemClass = `
DECLARE
  classes STRING;
SET
  classes = (
  SELECT
  CONCAT('"', STRING_AGG(DISTINCT itemClass, '", "'), '"')
  FROM
    \`<project>.<dataset>.sales_exec_summary_yoy\`);
EXECUTE IMMEDIATE
  FORMAT('''
  WITH
    t1 AS (
    SELECT
      <SELECTED_PERIOD> AS timePeriod,
      itemClass,
      SUM(grossSales) AS grossItemSales,
    FROM
      \`<project>.<dataset>.sales_exec_summary_yoy\`
    <WHERE CLAUSE>
    GROUP BY
      timePeriod,
      itemClass)
  SELECT
    timePeriod, (
    SELECT
      AS STRUCT T1.* EXCEPT(timePeriod)) AS grossItemSales
  FROM
    T1 PIVOT (SUM(IFNULL(grossItemSales,0)) FOR itemClass IN (%s)) T1
  ORDER BY
    timePeriod ASC''',classes);`;

export const dearSalesTable = `
WITH
  t1 AS (
  SELECT
    DATE(DATETIME_TRUNC(\`date\`, WEEK)) AS timePeriod,
    customerCategory,
    customer,
    itemClass,
    sku,
    orderStatus,
    SUM(grossSales) AS grossSales,
    SUM(grossSalesLY) AS grossSalesLY,
    SUM(grossQuantity) AS grossQuantity,
    SUM(grossQuantityLY) AS grossQuantityLY,
    SUM(grossTax) AS grossTax,
    SUM(grossTaxLY) AS grossTaxLY,
    SUM(grossAdditionalCharges) AS grossAdditionalCharges,
    SUM(grossAdditionalChargesLY) AS grossAdditionalChargesLY,
    SUM(netSales) AS netSales,
    SUM(netSalesLY) AS netSalesLY,
    SUM(grossProfit) AS grossProfit,
    SUM(grossProfitLY) AS grossProfitLY,
    SUM(cogsAmount) AS cogsAmount,
    SUM(cogsAmountLY) AS cogsAmountLY,
    SUM(numberOfOrdersFlag) AS numberOfOrdersFlag,
    SUM(numberOfOrdersFlagLY) AS numberOfOrdersFlagLY
  FROM
    \`<project>.<dataset>.sales_exec_summary_yoy\`
  <WHERE CLAUSE>
  GROUP BY
    DATE(DATETIME_TRUNC(\`date\`, WEEK)),
    2,
    3,
    4,
    5,
    6)
SELECT
  *
FROM
  t1
WHERE
(ifnull(grossSales,0) + ifnull(grossSalesLY,0) + ifnull(grossQuantity,0) + ifnull(grossQuantityLY,0) + 
ifnull(grossTax,0) + ifnull(grossTaxLY,0) + ifnull(grossAdditionalCharges,0) + ifnull(grossAdditionalChargesLY,0) + 
ifnull(netSales,0) + ifnull(netSalesLY,0) + ifnull(grossProfit,0) + ifnull(grossProfitLY,0) + ifnull(cogsAmount,0) + 
ifnull(cogsAmountLY,0) + ifnull(numberOfOrdersFlag,0) + ifnull(numberOfOrdersFlagLY,0)) != 0
ORDER BY
  timePeriod DESC`;
