import { netsuiteSalesSummaryScorecards as netsuiteSalesSummaryScorecardsConfig } from "./netsuiteQueries";
import { netsuiteRevenueChart as netsuiteRevenueChartConfig } from "./netsuiteQueries";
import { netsuiteRevenueChartClass as netsuiteRevenueChartClassConfig } from "./netsuiteQueries";
import { netsuiteRevenueChartCustomer as netsuiteRevenueChartCustomerConfig } from "./netsuiteQueries";
import { netsuiteSalesFilters as netsuiteSalesFiltersConfig } from "./netsuiteQueries";
import { netsuiteSalesTable as netsuiteSalesTableConfig } from "./netsuiteQueries";
import { dearScorecardSales as dearScorecardSalesConfig } from "./dearQueries";
import { dearSalesFilters as dearSalesFiltersConfig } from "./dearQueries";
import { dearRevenueChartCustomer as dearRevenueChartCustomerConfig } from "./dearQueries";
import { dearRevenueChart as dearRevenueChartConfig } from "./dearQueries";
import { dearRevenueChartItemClass as dearRevenueChartItemClassConfig } from "./dearQueries";
import { dearSalesTable as dearSalesTableConfig } from "./dearQueries";
import { isEmpty } from "lodash";

export interface IBigqueryQueryConfigMap {
  [queryName: string]: string;
}

function renderQueryForPeriodRollUp(
  queryConfigTemplate: string,
  timePeriod?: string
) {
  let renderedQueryForPeriod = queryConfigTemplate;

  if (typeof timePeriod !== "undefined") {
    let timePeriodFormatted = `DATE(DATETIME_TRUNC(date, ${timePeriod.toUpperCase()}))`;
    renderedQueryForPeriod = queryConfigTemplate.replaceAll(
      "<SELECTED_PERIOD>",
      timePeriodFormatted
    );
  }

  return renderedQueryForPeriod;
}

function generateFilterSqlWhereClause(filters: any) {
  const clauses = []; // Initialize an array to hold the generated WHERE clauses for each filter
  for (const filterName in filters) {
    if (filters[filterName].length > 0) {
      // If the filter has values, generate a WHERE clause for it
      // 4-9-23 - added replace to escape ' for stuffed puffs walmart naming
      const filterValues = filters[filterName].map((filter: any) =>
        filter.value.replaceAll("'", "\\'")
      ); // Get the filter values (i.e., the 'value' property of each object in the array)
      const clause = `${filterName} IN (${filterValues
        .map((value: any) => "'" + value + "'")
        .join(", ")})`; // Generate a WHERE clause for the filter (e.g., "customer_category IN ('Club', 'Discount', 'Distributor')")
      clauses.push(clause); // Add the WHERE clause to the array of clauses
    }
  }

  // If any WHERE clauses were generated, concatenate them into a single string with "AND" between them; otherwise, return an empty string
  return clauses.length > 0 ? "WHERE " + clauses.join(" AND ") : "";
}

function generateDateSqlWhereClause(filters: any) {
  if (
    filters.dateRanges !== undefined &&
    filters.dateRanges.startDate !== undefined &&
    filters.dateRanges.endDate !== undefined
  ) {
    const startDate = filters.dateRanges.startDate;
    const endDate = filters.dateRanges.endDate;

    const whereClause = `WHERE date >= '${startDate}' AND date <= '${endDate}'`;

    // If any WHERE clauses were generated, concatenate them into a single string with "AND" between them; otherwise, return an empty string
    return whereClause.length > 0 ? whereClause : "";
  }
}

function renderQueryFilters(queryConfigTemplate: string, filters?: any) {
  let renderQueryFilters = queryConfigTemplate;

  if (filters) {
    const whereClause = generateFilterSqlWhereClause(filters);
    const dateWhereClause = generateDateSqlWhereClause(filters);

    const clauses = [];

    if (whereClause && whereClause.length > 0) {
      clauses.push(whereClause.split("WHERE ")[1]);
    }

    if (dateWhereClause && dateWhereClause.length > 0) {
      clauses.push(dateWhereClause.split("WHERE ")[1]);
    }

    const finalWhereClause =
      clauses.length > 0 ? "WHERE " + clauses.join(" AND ") : "";

    renderQueryFilters = queryConfigTemplate.replaceAll(
      "<WHERE CLAUSE>",
      finalWhereClause
    );
  }

  return renderQueryFilters;
}

export function getQueryConfig(
  queryName: string,
  ERP: string,
  timePeriod?: string,
  filters?: any
) {
  let queryConfigTemplate = "";
  if (ERP === "Netsuite") {
    const netsuiteQueryConfigTemplates: IBigqueryQueryConfigMap = {
      "Sales Scorecards": netsuiteSalesSummaryScorecardsConfig,
      "Sales over Time": netsuiteRevenueChartConfig,
      "Customer Sales": netsuiteRevenueChartCustomerConfig,
      "Product Sales": netsuiteRevenueChartClassConfig,
      "Sales Summary Table": netsuiteSalesTableConfig,
      SalesFilters: netsuiteSalesFiltersConfig,
    };

    if (!Object.keys(netsuiteQueryConfigTemplates).includes(queryName)) {
      throw new Error(
        `Requested invalid query config: ${queryName} for ERP: ${ERP}`
      );
    }
    queryConfigTemplate = netsuiteQueryConfigTemplates[queryName];
  } else if (ERP === "DEAR") {
    const dearQueryConfigTemplates: IBigqueryQueryConfigMap = {
      "Sales Scorecards": dearScorecardSalesConfig,
      "Sales over Time": dearRevenueChartConfig,
      "Customer Sales": dearRevenueChartCustomerConfig,
      "Product Sales": dearRevenueChartItemClassConfig,
      "Sales Summary Table": dearSalesTableConfig,
      SalesFilters: dearSalesFiltersConfig,
    };

    if (!Object.keys(dearQueryConfigTemplates).includes(queryName)) {
      throw new Error(
        `Requested invalid query config: ${queryName} for ERP: ${ERP}`
      );
    }
    queryConfigTemplate = dearQueryConfigTemplates[queryName];
  } else {
    throw new Error(`invalid ERP name provided ${ERP}`);
  }

  // console.log(queryConfigTemplate);
  const queryConfigForPeriod = renderQueryForPeriodRollUp(
    queryConfigTemplate,
    timePeriod
  );

  const queryConfigForFilters = renderQueryFilters(
    queryConfigForPeriod,
    filters
  );
  // console.log(queryConfigForFilters);
  if (queryConfigForFilters != "") {
    return queryConfigForFilters;
  } else {
    throw new Error(
      `Returned blank query config: ${queryName} for ERP: ${ERP}`
    );
  }
}

export function getQueryConfigs(
  queryNames: string[],
  ERP: string,
  timePeriod?: string,
  filters?: any
): IBigqueryQueryConfigMap {
  const queryConfigs = queryNames
    .filter((name) => !isEmpty(name))
    .reduce((queryConfigMap, queryName) => {
      return {
        ...queryConfigMap,
        [queryName]: getQueryConfig(queryName, ERP, timePeriod, filters),
      };
    }, {});
  return queryConfigs;
}
